import React from "react";

const Banner3 = ({ text1, text2, imgPath, bgColor, minHeight }) => {
  return (
    <section className="banner-style-3" style={{ backgroundImage: imgPath }}>
      <div
        className="wrapper"
        // style={{ backgroundColor: bgColor, minHeight: minHeight }}
      >
        <div className="container">
          <div className="row" 
          // style={{ minHeight: minHeight }}
          >
            <div
              className="col-lg-6 col-sm-12 text-center"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <h1 className="display-5 text-white">{text1}</h1>
              {/* <h1 className="display-5 text-white mb-0">{text2}</h1> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner3;
