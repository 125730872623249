import React from "react";
import "./BestDeliveryBox.scss";

const Section1 = ({ url }) => {
  return (
    <>
      <div
        className="products-main-card"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <img
          src={url + "images/blogs/blog-9.png"}
          className="product-card-img"
          alt="6 best mailboxes to prevent porch piracy"
          data-aos="fade-up"
          data-aos-duration="2000"
        />
        <div className="product-card-body mt-4">
          <div className="product-card-text">
            <p>
              With the rise of online shopping, having a reliable mailbox for
              package deliveries has become more important than ever. However,
              not all mailboxes are created equal; some are better suited to
              handling packages than others. Here are some of the best mailboxes
              for online packages, including the smartest one -{" "}
              <span className="card-text-span">Brain Box</span>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Section1);
