import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/BestDeliveryBox/Section1";
import Section2 from "../includes/BestDeliveryBox/Section2";
import Section3 from "../includes/BestDeliveryBox/Section3";
import { Helmet } from "react-helmet";

const BestDeliveryBox = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/blogs/best-mailbox-for-packages",
    },
    headline: "6 Best mailbox for packages to prevent porch piracy in 2023",
    image: "https://brainbox.company/images/blogs/blog-9.png",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2023-05-19",
    dateModified: "2023-05-20",
  };

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>
          6 Best Mailboxes for Packages to Prevent Porch Piracy In 2023
        </title>
        <meta
          name="description"
          content="Which mailbox suits you depends on your requirements. Here is a list of the top 6 mailboxes to receive online parcels and deter porch piracy. "
        />
        <link
          rel="canonical"
          href="https://brainbox.company/blogs/best-mailbox-for-packages"
        />
      </Helmet>
      <main>
        <article className="our-team">
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={
              "6 Best Mailboxes for Packages to prevent porch piracy in 2023"
            }
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
            <Section2 url={devUrl} />
            <Section3 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(BestDeliveryBox);
