import React from "react";
import Banner5 from "../partials/Banner/Banner5";
import Section1 from "../includes/OurTeam/Section1";
import Section2 from "../includes/OurTeam/Section2";
import { Helmet } from "react-helmet";

const OurTeam = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Our Team</title>
        <link rel="canonical" href="https://brainbox.company/our-team" />
      </Helmet>
      <main>
        <article className="our-team">
          <Banner5
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"Meet the brains"}
            text2={"behind the box"}
          />
          <div
            className="bg-design"
            // style={{ backgroundImage: "url(" + devUrl + "./images/our-team/bg-bottom.png)" }}
          >
            <img
              src="./images/our-team/bg-bottom.png"
              className="bg-img-team"
              alt=""
            />
            <Section1 url={devUrl} />
            <Section2 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(OurTeam);
