import React from "react";
// import Banner from "../partials/Banner/Banner";
import Section1 from "../includes/HowItWorks/Section1";
// import Section11 from "../includes/HowItWorks/Section11";
import Section2 from "../includes/HowItWorks/Section2";
import Section3 from "../includes/HowItWorks/Section3";
// import Section4 from "../includes/HowItWorks/Section4";
import Section5 from "../includes/HowItWorks/Section5";
// import Section13 from "../includes/HowItWorks/Section13";
// import Section14 from "../includes/HowItWorks/Section14";
import { Helmet } from "react-helmet";

const HowItWorks = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/how-it-works/howit-works-1.png)";

  return (
    <>
      <Helmet>
        <title>How It Works</title>
        <link rel="canonical" href="https://brainbox.company/how-it-works" />
      </Helmet>
      <main>
        <article className="howItWorks">
          {/* <Banner text={"How It Works"} imgPath={imgPath} /> */}
          <Section1 url={devUrl} imgPath={imgPath} />
          <Section3 url={devUrl} />
          <Section2 url={devUrl} />
          {/* <Section4 url={devUrl} /> */}
          <Section5 url={devUrl} />
          {/* <Section11 /> */}
          {/* <Section13 url={devUrl} />
        <Section14 url={devUrl} /> */}
        </article>
      </main>
    </>
  );
};

export default React.memo(HowItWorks);
