import React from "react";

const Section1 = ({ url }) => {
  return (
    <section className="section1">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 wrapper1">
            <h5>Privacy and Confidentiality</h5>
            <p>
              Welcome to Brain Box. The security of your personal information is
              of utmost importance to us, and your privacy is highly valued. The
              steps taken to protect this information are explained below in
              detail under the Privacy Policy.
              <br /> <br />
              To provide our customers with a better understanding, the Privacy
              Policy highlights the data collection process, its usage, as well
              as the circumstances under which this data may be disclosed. Steps
              taken to secure the obtained personal information are also
              elaborated upon. By visiting this site through any channels, you
              have accepted the laid-out policies.
              <br /> <br />
              Protecting customers’ data and respecting their privacy is
              imperative to Brain Box therefore, your name along with any other
              personal data, will only be utilized in the way explained in this
              Privacy Policy. Your information will only be collected if it is
              relevant to the ordering process.
              <br /> <br />
              This data will only be kept for as long as it is necessary,
              however, it may not necessarily be accessible to our team. It may
              also remain with Brain Box if legally required. The website can be
              opened and browsed anonymously. A visitor will only be identified
              if they already have an account and log in through their username
              and password.
            </p>
          </div>
          <div className="col-12 wrapper2">
            <h5>What personal data does Brain Box collect?</h5>
            <p>
              For visitors that place an order, multiple bits of personal
              information may be required.
              <br /> <br />
              This data is gathered and stored to provide you with information
              through our website as well as to process customer purchases while
              considering possible claims that may arise later. The information
              collected may include but is not limited to your following
              details: name, gender, date of birth, email address, delivery
              address, phone number, and bank account particulars or any other
              data necessary for online payment.
              <br /> <br />
              Furthermore, this data is also utilized to communicate with you as
              well as to improve the services provided by Brain Box. It is used
              to administer your account which includes customer authentication,
              audit download data, and enhancing website layout. To better cater
              to consumer needs, Brain Box researches visitors’ demographics and
              incorporates the results into our customer relationship management
              process. Through this, customers can be notified about their
              required information and, with consent, can also receive email
              alerts. You may opt-out of this service any time you need.
              <br /> <br />
              The information gathered, specifically name and address, may be
              shared with a third party (e.g., a courier) to carry out the
              delivery process. Therefore, this data needs to be accurate and if
              any changes are made, Brain Box should immediately be notified.
              <br />
              <br />
              The data customers provide is visible to them once they have
              logged in. Moreover, details regarding your order, such as its
              dispatch and competition status, are also visible here. Kindly
              choose the option to make your data inaccessible to third parties
              as Brain Box is not liable for any misuse unless our team is
              responsible for it.
            </p>
          </div>
          <div className="col-12 wrapper1">
            <h5>Further Uses</h5>
            <p>
              The statistics of personal data provided may also be used for
              market research anonymously however, the customer reserves the
              right to opt out of this at any time. Any information received
              through polls and surveys will be kept strictly confidential. Your
              email may be disclosed to a third party only if you choose to opt
              for competition in these surveys.
              <br /> <br />
              Brain Box may also email you information about its products, sales
              promotions, newsletters, etc. If the customers wish not to receive
              these, they may click the ‘unsubscribe’ link in the email anytime
              and their request will be fulfilled within 7 working days.
              <br /> <br />
              The data gathered from consumers may be utilized in an anonymized
              format for certain purposes such as gathering a general location
              for research purposes. Moreover, this may also be provided to
              publishers however, no individual will be identified through it.
            </p>
          </div>

          <div className="col-12 wrapper2">
            <h5>Brain Box and Third Parties</h5>
            <p>
              Brain Box will only share your data with third parties who require
              it to procure and deliver the ordered item as well as to manage
              customer service. This may include agents, subcontractors,
              couriers, and relevant personnel for payment collection.
              <br /> <br />
              The information may also be shared with a third party for fraud
              protection as well as credit risk reduction. If Brain Box is sold,
              customer databases may be sold along with it. Other than for the
              clauses listed in this privacy policy, any personal data will not
              be disclosed unless there is a legal obligation. Any breach of
              privacy from the third-party advertisements present on the website
              will not be Brain Box’s responsibility.
            </p>
          </div>

          <div className="col-12 wrapper1">
            <h5>Cookies</h5>
            <p>
              Cookies may be defined as small text files that are unique to
              every user. While acceptance of cookies is not necessary to visit
              the website, Brain Box’s system employs them to recognize your
              browser and device and provide you with better service for your
              ease and save time. These improvements may include amendment of
              the shopping cart without re-entering data such as email addresses
              to make it more convenient. Browsers may be set to not accept
              cookies, but they may diminish customer service.
              <br /> <br />
              For data analysis purposes, Brain Box uses Google Analytics; a web
              service in form of text files placed on consumers’ computers that
              transfers data generated, such as your IP address, and stores it
              in Google servers. Google analyzes and evaluates this information
              to compile activity reports for website operators. Google may also
              provide this information to a third party if legally required or
              for data processing. While it may be generated, your IP address
              will not be associated with any other information collected by
              Google. The use of cookies may be refused; however, certain parts
              of the site may become inaccessible. By using this website, you
              are consenting to the cookies used listed above.
            </p>
          </div>
          <div
            className="col-12 wrapper3"
            style={{
              backgroundImage: "url(" + url + "images/privacy-watermark.png",
            }}
          >
            <div className="row boxWrapper">
              <div className="col-12 col-md-4 box">
                <h5>Security</h5>
                <p>
                  Brain Box has put measures in place to ensure that your data
                  is safe from unauthorized and unlawful access or accidental
                  loss and destruction. The data is collected on a secure server
                  with a firewall. However, this level of security may require
                  repeated proof of identity and therefore request for personal
                  information for your security. Any breaches on your end are
                  entirely your responsibility.
                </p>
              </div>
              <div className="col-12 col-md-4 box">
                <h5>Your Rights</h5>
                <p>
                  If you are concerned about your data, you may request access
                  to it and ask for any inaccuracies to be corrected free of
                  cost. At any point, you reserve the right to ask Brain Box to
                  stop using your personal information for direct marketing
                  purposes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
