import React from "react";
import { Link } from "react-router-dom";
import "./Card.scss";

function MainBlogCard(props) {
  return (
    <>
      <div className="blog-card card">
        <img className="card-img-top" src={props.imgsrc} alt={props.alttxt} />
        <div className="blog-card-body card-body">
          <div className="blog-card-title card-title mt-4">
            <h2>{props.blogtitle}</h2>
          </div>
          <div className="col-12 text-center">
            <div className="mt-5 mb-4">
              <Link to="/blogs/package-theft-punishments" className="btn btn-primary-2 top-card-btn">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainBlogCard;
