import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/SmartDeliveryBox/Section1";
import { Helmet } from "react-helmet";

const SmartDeliveryBox = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/blogs/smart-delivery-box",
    },
    headline: "What makes Brain Box a smart delivery box?",
    image: "https://brainbox.company/images/blogs/blog-5.png",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2023-03-13",
    dateModified: "2023-03-14",
  };

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Brain Box – A Smart Delivery Box</title>
        <meta
          name="description"
          content="A smart delivery box that integrates cutting-edge technology to provide its users with the most secure and convenient delivery experience possible."
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://brainbox.company/smart-delivery-box" />
      </Helmet>
      <main>
        <article className="our-team">
          {/* the following script is for reference, as schema markup can be added in the main tag as well */}
          {/* <script type="application/ld+json">
              {JSON.stringify(schemaMarkup)}
            </script> */}
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"What Makes Brain Box Smart?"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(SmartDeliveryBox);
