import React from "react";
import { Link } from "react-router-dom";
import "./Card.scss";

function BlogCard(props) {
  return (
    <>
      <div className="blog-card card">
        <img className="card-img-top" src={props.imgsrc} alt={props.alttxt} />
        <div className="blog-card-body card-body">
          <div className="blog-card-title card-title">{props.blogtitle}</div>
          <p className="blog-card-text card-text">{props.blogtext}</p>
          <Link
            to={props.bloglink}
            className="blog-btn col-6 offset-8"
            data-aos="fade-in"
            data-aos-duration="2000"
          >
            Read More
          </Link>
        </div>
      </div>
    </>
  );
}

export default BlogCard;
