import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/PreventPackageTheft/Section1";
import { Helmet } from "react-helmet";

const PreventPackageTheft = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/blogs/prevent-package-theft",
    },
    headline: "How to prevent package theft?",
    image: "https://brainbox.company/images/blogs/blog-2.jpg",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2023-02-21",
    dateModified: "2023-02-22",
  };

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Stop Porch Piracy – How to Prevent Package Theft?</title>
        <meta
          name="description"
          content="Package theft is a growing problem in many communities, with thieves targeting packages left on doorsteps and porches. Learn about the prevention of package theft."
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://brainbox.company/prevent-package-theft" />
      </Helmet>
      <main>
        <article className="our-team">
          {/* the following script is for reference, as schema markup can be added in the main tag as well */}
          {/* <script type="application/ld+json">
              {JSON.stringify(schemaMarkup)}
            </script> */}
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"How to prevent package theft?"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(PreventPackageTheft);
