import React from "react";
import { Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

const Section3 = ({ url }) => {
  return (
    <>
      <section
        className="section-3"
      >
       
        <div className="container-fluid">
          <div className="row sec3-main">
          <div 
          className="col-xl-6 sec3-ls"
          style={{
          backgroundImage: "url(./images/home/section3-home-1.png)",
          }}
          >
          <h2
              className="sec3-h1 mt-5"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
                No Need To Think<br /><span className="heading_gr"> Out Of The Box</span> 
          </h2>
          <p
              className="sec3-p1 mt-5"
              data-aos="fade-right"
              data-aos-duration1="2000"
            >
              Brain Box provides a secure, weatherproof alternative to manage deliveries conveniently through your phone. No locks or special codes are required – the package is delivered by scanning its barcode to open the appropriate Box compartment to place your package inside.
          </p>
          <p
              className="sec3-p1 mt-2"
              data-aos="fade-right"
              data-aos-duration1="2000"
            >
              No need to request shipments in plain packaging. Brain Box offers a spacious and stylish way to get rid of the last hurdle in online shopping. What is the use of online shopping if the last mile for your package is insecure? The list of worries about secure package delivery can go on and on unless you’re talking about Brain Box!
          </p>
          <p
            className="sec3-p1 mt-2"
            data-aos="fade-right"
            data-aos-duration1="2000"
            >
              Brain Box is so easy to use. In case the product does not feature a barcode, the delivery person can request access to the appropriate storage compartment through the Brain Box secure app. The owner receives a notification through the app on their mobile device and can unlock it for the items to be stored safely in the brainiest mailbox for packages in town.
          </p>
          <Link 
            to="/how-it-works" 
            className="btn sec3-btn-green mt-5"
            data-aos="fade-right"
            data-aos-duration1="2000"
            >
            How it Works
          </Link>

          </div>
          <div className="col-xl-6 sec3-rs">
            <Carousel fade>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block"
                  src="./images/home/sec3-img1.jpg"
                  alt="Package drop box to prevent package theft"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block"
                  src="./images/home/sec3-img2.jpg"
                  alt="Parcel drop box for home"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block"
                  src="./images/home/sec3-img3.jpg"
                  alt="Smart and secure mailbox for packages"
                />
              </Carousel.Item>
              <Carousel.Item interval={1000}>
                <img
                  className="d-block"
                  src="./images/home/sec3-img4.jpg"
                  alt="Ensuring last-mile security in online shopping"
                />
              </Carousel.Item>
            </Carousel>
          </div>
              
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Section3);
