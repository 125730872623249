import React from "react";
import { Link } from "react-router-dom";


const Section1 = ({ url }) => {
  return (
    <section className="section1 blog-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <img
            src={url + "images/blogs/blog-1.jpeg"}
            className="shadow"
            alt="What to do when package is stolen?"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                When a package is stolen, it can be a frustrating and stressful
                experience. However, there are a few steps that can be taken to
                report the theft and potentially recover the package or receive
                compensation.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Contact Logistic Partner</h2>
              <p className="blog-title-text">
                The first step is to contact the delivery company. If the
                package was shipped via a major carrier such as FedEx, UPS, or
                the United States Postal Service (USPS), they will have a
                process in place for reporting stolen packages. This will
                typically involve filing a claim, which may require providing
                proof of value for the items that were stolen.
              </p>
              <p className="blog-title-text">
                If the package was delivered by a private carrier or through a
                service like Amazon, contact them and let them know the package
                is missing. They will investigate and see if the package was
                delivered or not. If the package was not delivered, they will
                refund the amount or re-ship the package.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Reach Out to The Seller</h2>
              <p className="blog-title-text">
                Next, contact the seller. If the package was purchased online,
                the seller may be able to provide additional information or
                assistance in resolving the issue. They may also be able to
                provide a refund or replacement for the stolen items.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Check With Neighbors</h2>
              <p className="blog-title-text">
                Additionally, check with your neighbors to see if they received
                the package by mistake or if they saw anything suspicious.
                Sometimes, packages are delivered to the wrong address and can
                be recovered with the help of your neighbors.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Report to Law Enforcement</h2>
              <p className="blog-title-text">
                You should also report the theft to the police. While they may
                not be able to recover the stolen package, filing a police
                report can help to document the incident and may be required to
                file a claim with the delivery company or receive compensation
                from the seller.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Other Preventative Measures</h2>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                It's also good to take some preventative measures, such as
                requesting a signature confirmation upon delivery or having the
                package delivered to a secure location like a work address or a
                package locker. You can also consider using package tracking
                devices, which will provide real-time updates on the location of
                your package and can alert you if it is delivered to an
                unexpected location or if it is tampered with.
              </p>
              <div data-aos="fade-up" data-aos-duration="2000">
                <p className="blog-title-text">
                  In conclusion, when a package is stolen, it's important to
                  contact the delivery company, the seller, your neighbors, and
                  the police as soon as possible. These steps will help to
                  document the incident and increase the chances of recovering
                  the package or receiving compensation for the stolen items.
                  Additionally, taking preventative measures like requesting a
                  signature confirmation and using package tracking devices can
                  help to protect your packages in the future.
                </p>
              </div>
              <div>
                <p className="blog-title-text">
                  The easiest and most reliable way to tackle this issue would
                  be to have a{" "}
                  <Link
                    to="/"
                    data-aos="fade-in"
                    data-aos-duration="2000"
                  >
                    secure package delivery box
                  </Link>{" "}
                  placed outside your address to receive parcels on your behalf.
                  The convenience presented by such a method to keep your
                  package safe would not only give you flexibility but also
                  remove any apprehensions about ordering expensive products
                  online.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
