import React from "react";
import { Link } from "react-router-dom";

const Section2 = ({ url }) => {
  return (
    <>
      <section className="section-2 hero-banner--two-col-style-4">
        <div className="container container2">
          <div className="row">
            <div className="col-md-8 col-xl-6 mx-auto box2 ">
              <div className="img-box">
                <div
                  className="bb-image-bg"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                ></div>
                <img
                  src={url + "images/about/about-img-1.jpg"}
                  className="bb-image"
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>

            <div
              className="col-xl-6 box1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h4>
                According to the Article by Mr. John Carlsen, Senior Staff
                Writer at Security and Smart Home, research suggests that:
                <br></br>
                <br></br>
                “Around 210 million packages disappeared from porches across
                America over the 12 months preceding our recent survey of 1,000
                Americans.”
                <br></br>
                <br></br>
                He lists porch piracy statistics for 2021 as:
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <ul data-aos="fade-in" data-aos-duration="2000">
                <li>
                  <p>
                    72% of Americans say the COVID-19 pandemic has made them
                    more concerned about porch piracy (up 9 points from last
                    year)
                  </p>
                </li>
                <li>
                  <p>
                    64.1 % of Americans have been a victim of package theft in
                    the last 12 months
                  </p>
                </li>
                <li>
                  <p>
                    More than 3 in 4 Americans have been a victim of package
                    theft in their lifetime
                  </p>
                </li>
                <li>
                  <p>
                    53.5 % have had multiple packages stolen in the last 12
                    months
                  </p>
                </li>
                <li>
                  <p>
                    More than half of all packages stolen are valued between
                    $50-$200
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container container1">
          <div className="row">
            <div
              className="col-xl-6 box1 order-2 order-xl-1"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h4>
                Furthermore, he states that “people are responding more strongly
                to package theft. Here are some steps victims of package theft
                reported taking after the incident”, noting the following as
                safety measures:
              </h4>
            </div>
            <div className="col-md-8 col-xl-6 mx-auto box2 order-1 order-xl-2">
              <div className="img-box">
                <div
                  className="bb-image-bg"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                ></div>
                <img
                  src={url + "images/about/about-img-2.jpg"}
                  className="bb-image"
                  alt=""
                  data-aos="fade-left"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <ul data-aos="fade-in" data-aos-duration="2000">
                <li>
                  <p>
                    56% said they added a home security system after the
                    incident (almost double the previous year)
                  </p>
                </li>
                <li>
                  <p>
                    46% say they alerted neighbors about the incident (up over
                    10 points from last year)
                  </p>
                </li>
                <li>
                  <p>
                    38% arranged for carriers to leave parcels in a discreet
                    spot (up nearly 9 points from 2020)
                  </p>
                </li>
                <li>
                  <p>
                    And now, you can purchase Brain Box; all package theft
                    concerns will cease to exist!
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xl-12 text-center">
              <Link to="/quote" className="btn btn-primary-2 py-2">
                Request for Quote
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Section2);
