import React from "react";

const Section2 = ({ url }) => {
  return (
    <section className="section2">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 text-center">
            <div
              className="text-with-bg-style-3 mx-auto"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="text-bg"></div>
              <h1 className="section-title">
                <span>The dedicated</span> team
                <br></br>
                <span>involved in</span> the project
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section2);
