import React from "react";
import { Link } from "react-router-dom";

const Section5 = ({ url }) => {
  return (
    <>
      <section
        className="section-5 sec5-extra-css"
      >
        <div className="container">
          <div className="row">
          <div className="col-xl-5">
            <div className="text-wrapper sec5-cs">
              <h2
              className="sec5-h1"
              data-aos="fade-right"
              data-aos-duration="2000"
              >Brain Box At <br></br>Your Fingertips!
              </h2>
              <p 
              data-aos="fade-right"
              data-aos-duration="2000"
              >
                Access your favorite delivery lock box with a single tap! Monitor progress, keep track of deliveries, and share access with other members of the family through Brain Box Secure App.
              </p>
              <Link 
                to="/quote" 
                className="btn sec3-btn-green mt-3"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                Request for Quote
              </Link>
            </div>
          </div>
          <div className="col-xl-2 text-center">
            <div className="img-box">
              <div className="bb-image-bg-style-sec5"></div>
              <img
                src={url + "images/home/two-phones-new.png"}
                className="bb-image-sec5"
                alt="Brain Box Mobile App for Customers"
                data-aos="fade-left"
                data-aos-duration="2000"
              />
            </div>
          </div>
          </div>
        </div>
        <div className="bg-white"></div>
      </section>
    </>
  );
};

export default React.memo(Section5);
