import React from 'react';



const Banner2 = ({text,imgPath}) => {


    return(

        <section className='banner2' style={{ backgroundImage : imgPath }}>
          
            <h2>
                {text}
            </h2>
          
        </section>
    )

}

export default Banner2