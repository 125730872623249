import React from "react";
import Sensor2 from "../../partials/Commons/Sensor2/Sensor2";

const Section5 = ({ url }) => {
  return (
    <>
      <section
        className="section-5 hero-banner--two-col-style-3"
        style={{
          backgroundImage: "url(./images/how-it-works/home-bg.jpg)",
        }}
      >
        <div className="container-fluid container1">
          <div className="row">
            <div className="col-xl-6 ps-xl-0 overflow-hidden">
              <div className="main-wrapper">
                <img
                  src={url + "images/home/box-wave.png"}
                  className="bb-image"
                  alt="..."
                  data-aos="fade-right"
                  data-aos-duration="2000"
                ></img>

                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 text-center">
              <h1 data-aos="fade-left" data-aos-duration="2000">
                On-Device
                <br></br>
                <span>Control Interface</span>
              </h1>
            </div>
          </div>
        </div>

        <Sensor2 />
      </section>
    </>
  );
};

export default React.memo(Section5);
