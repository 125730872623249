import React from "react";
// import bannericon from "./images/brainbox-icon.png";
const Banner4 = ({ text1, text2, imgPath, bgColor, minHeight }) => {
  return (
    <section className="banner-style-4" style={{ backgroundImage: imgPath }}>
      <div className="wrapper">
        <div className="container">
          <div className="row" 
          >
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="banner4-content"
              data-aos="fade-up"
              data-aos-duration="2000"
              >
              <h1>THINK PACKAGE SAFETY,</h1>
                <h1><span>THINK BRAIN BOX</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner4;
