import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/Blogs/Section1";
import { Helmet } from "react-helmet";

const Blogs = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <link rel="canonical" href="https://brainbox.company/blogs" />
      </Helmet>
      <main>
        <meta name="description" content="" />
        <article className="our-team">
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"BLOGS"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(Blogs);
