import React from "react";

const Section1 = ({ url }) => {
  return (
    <section className="section-1">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-7 mx-auto">
            <div
              className="text-with-bg text-center p-4"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <p>
                Make a statement with Brain Box; Say NO to package theft. Keep
                your package secure with its temperature-controlled,
                compartmentalized features.
                <br></br>
                <br></br>
                The high-quality materials used in the production of Brain Box
                mean that your house retains its curbside appeal while you
                operate and control it remotely, or in person. Brain Box is
                spacious enough to store large and small packages, and its
                temperature-controlled sensors let you know when the ice cream
                is losing its cool.
                <br></br>
                <br></br>
                The brand-new iPhone you waited months for, or the ice cream you
                were craving? All gone. Hundreds of dollars up in the air. As it
                is, these circumstances plague the online shopping experience.
                With no place to keep your items safe, you fear porch piracy.
                <br></br>
                <br></br>
                During the pandemic, our only consolation came from online
                shopping; through freshly packaged brown boxes at the doorstep.
                <br></br>
                <br></br>
                Deep in a state of constant change, time has also changed the
                shopping experience. What was previously done physically is now
                just a few taps away, at your convenience! A few clicks and the
                package is at your doorstep. As exciting as the concept is, it’s
                also anxiety-inducing, especially because matching schedules
                with delivery timings proves to be a hassle. The worry of having
                your package stolen plagues you – you either have to race from
                work to retrieve the package or would come home to an empty
                porch, items stolen.
                <br></br>
                <br></br>
                That is the mindset we went in with while creating Brain Box –
                your new, state-of-the-art safe-keeping experience!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
