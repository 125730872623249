import React from "react";
// import Banner from "../partials/Banner/Banner";
import Banner3 from "../partials/Banner/Banner3";
import Section1 from "../includes/Quote/Section1";
import { Helmet } from "react-helmet";

const Quote = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/quote/banner-top.jpg)";
  const minHeight = "700px";

  return (
    <>
      <Helmet>
        <title>Request Quote</title>
        <link rel="canonical" href="https://brainbox.company/quote" />
      </Helmet>
      <main>
        <article className="quote">
          <Banner3
            text1={"Request for Quote"}
            imgPath={imgPath}
            minHeight={minHeight}
          />
          {/* <Banner text={"Request for Quote"} imgPath={imgPath} /> */}
          <Section1 url={devUrl} />
        </article>
      </main>
    </>
  );
};

export default React.memo(Quote);
