import React from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  return (
    <section className="section1 blog-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <img
            src={url + "images/blogs/blog-5.png"}
            className="shadow"
            alt="Smart Delivery Box"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Brain Box is the epitome of smart delivery technology,
                revolutionizing how we receive and store packages. It is a
                state-of-the-art, automated{" "}
                <Link to="/">
                  secure package delivery box
                </Link>{" "}
                that integrates cutting-edge technology to provide its users
                with the most secure and convenient delivery experience
                possible.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                At the heart of Brain Box lies its advanced locking system,
                which uses smart key technology to allow for automated unlocking
                and locking of the box. This ensures that only authorized
                individuals can access the box, and packages are always stored
                securely.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                In addition to its locking system, Brain Box also features
                real-time monitoring and tracking capabilities, allowing users
                to monitor their deliveries at all times. This way, users can
                know exactly when their package has been delivered and even
                receive notifications when the box is opened.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Brain Box offers advanced temperature control for those with
                more delicate or weather-sensitive deliveries. It maintains the
                optimum temperature for items that need to be kept at a desired
                temperature, ensuring safety and protection.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Brain Box is also designed to work seamlessly with popular
                delivery and logistics services, making it easier than ever to
                receive and store packages. And for added convenience, the box
                can be controlled and monitored remotely through an app or web
                portal.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Finally, Brain Box is equipped with tamper-evident seals that
                alert users of unauthorized access, providing peace of mind and
                ensuring the security of deliveries.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Overall, Brain Box is a smart delivery box that combines
                security, convenience, and cutting-edge technology to provide
                the ultimate delivery experience.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Did you know the average price of a stolen package in the USA is
                50$?{" "}
                <Link
                  to="/blogs/stolen-packages-trend-in-usa"
                >
                  Learn more
                </Link>
                .
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Features</h2>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Advanced features like the following categorize Brain Box as a
                smart delivery box:
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                1. Remote access, allowing the user to lock/unlock the box
                remotely using a smartphone or other device.<br /><br />2. Smart weight
                sensors detect when a package has been delivered and alert the
                user.<br /><br />3. Real-time monitoring and tracking capabilities.<br /><br />4. Secure
                storage for packages and deliveries.<br /><br />5. Advanced temperature and
                humidity control sensors to keep sensitive items at a desired
                temperature.<br /><br />6. Integration with popular delivery and logistics
                services.<br /><br />7. Remote access and control through an app or web
                portal.<br /><br />8. Tamper-evident seals to alert users of any
                unauthorized access.
              </p>
            </div>
            <div>
              <p className="blog-title-text">
                These features make Brain Box a convenient and secure package
                delivery and storage solution. Isn’t it the brainiest box in
                town to stop porch piracy? Learn{" "}
                <Link
                  to="/how-it-works"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  how it works
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
