import React from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, []);
  return (
    <>
      <section
        className="section-1"
        style={{
          backgroundImage: "url(./images/home/first-section.png)",
        }}
      >
      
        <div className="section-1-sub hero-banner--one-col-centered-style-1">
          <div className="wrapper text-center">
            <div 
            className="bb-circle-gradient-home"
            data-aos="fade-up"
            data-aos-duration="1000"
            >
            </div>
            
            <img
              className="home-banner-1"
              src={url + "images/home/home-banner-1.png"}
              alt=""
              data-aos="zoom-in"
              data-aos-duration="2000"
              width={"900px"}
            ></img>
            <img
                src={url + "images/home/brainbox-front.png"}
                className="banerbox-image"
                alt="Brain Box - a smart and secure package delivery box"
                data-aos="fade-down"
                data-aos-duration="2000"
                width={"500px"}
              />
            <img
                src={url + "images/home/brainbox-hive.png"}
                className="banerbox-image-2"
                alt="Brain Box - a smart and secure package delivery box"
                data-aos="fade-up"
                data-aos-duration="2000"
              width={"150px"}
              height={"250px"}
              />
          </div>
        </div>



        <div className="hero-banner--two-col-style-2 mt-5">
          <div className="container container1">


            <div className="row wrapper">
              <div className="col-xl-12 box1 text-center mb-f-res" style={{ paddingTop:"200px" }}>
              <h1
                  className="display-5 mt-5"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  Secure Package Delivery Box <br /><span className="heading_gr heading_gr_mb">Protecting Your Parcel</span> 
                </h1>
                <p
                  className="mt-5 ph_font_size"
                  data-aos="zoom-in"
                  data-aos-duration1="2000"
                >
                  Prevent Package theft with Brain Box – a smart lockable delivery box providing the freedom to securely receive online packages and food deliveries at any time. Are you always worried about porch pirates running off with your online order? Anxious about missing a delivery, or about coming home to spoiled groceries? Concerned about changing your schedule to sign off or receive online delivery? Looking for a smart delivery box or a secure package mailbox for your porch? <br></br> Now, there’s no need to worry about receiving your order in person, or matching personal schedules with delivery timings! Brain Box has lockable, spacious compartments to keep your products safe and secure from porch pirates and the weather!
                </p>
              </div>
            </div>
  
            <div 
            className="row mt-5"
            >
              <div 
              className="col-xl-12 text-center"
              data-aos="zoom-in"
              data-aos-duration="2000"
              >
                <h2 className="section-heading fw-bold">
                  <span className="heading_gr">Features</span>
                </h2>
            </div>
          </div>

          <div 
          className="row features-row"
          data-aos="zoom-in"
          data-aos-duration="2000"
          >
            <div className="col-xl-4">
              <div className="content-wrapper">
                <img
                  className="mx-3"
                  src={url + "images/home/features-icon/1location-sensors.png"}
                  alt=""
                ></img>
                <h5 className="text">Location Sensors and Capacity Sensors</h5>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="content-wrapper">
                <img
                  className="mx-3"
                  src={url + "images/home/features-icon/2temprature-sensor.png"}
                  alt=""
                ></img>
                <h5 className="text">
                  Temperature and Humidity Sensors<br></br> in Each Compartment
                </h5>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="content-wrapper">
                <img
                  className="mx-3"
                  src={
                    url + "images/home/features-icon/3compartment-locking.png"
                  }
                  alt=""
                ></img>
                <h5 className="text">Compartment Locking System</h5>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="content-wrapper">
                <img
                  className="mx-3"
                  src={url + "images/home/features-icon/4wifi-connectivity.png"}
                  alt=""
                ></img>
                <h5 className="text">WiFi Connectivity</h5>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="content-wrapper">
                <img
                  className="mx-3"
                  src={url + "images/home/features-icon/5family-sharing.png"}
                  alt=""
                ></img>
                <h5 className="text">Family-Sharing</h5>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="content-wrapper">
                <img
                  className="mx-3"
                  src={url + "images/home/features-icon/6scalable.png"}
                  alt=""
                ></img>
                <h5 className="text">Multiple Compartments</h5>
              </div>
            </div>
          </div>
          <div 
            className="row mt-5"
            >
              <div 
              className="col-xl-12 text-center"
              data-aos="zoom-in"
              data-aos-duration="2000"
              >
              <Link to="/how-it-works" className="btn sec3-btn-green">
                How it Works
              </Link>
            </div>
          </div>
          
          </div>
        </div>

      </section>
    </>
  );
};

export default React.memo(Section1);
