import React from "react";
import Banner from "../partials/Banner/Banner";
import Section11 from "../includes/About/Section11";
import { Helmet } from "react-helmet";

const AboutMobile = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/about1.jpg)";

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <link rel="canonical" href="https://brainbox.company/about-us" />
      </Helmet>
      <main>
        <article className="about">
          <Banner
            text={"Combating Porch Piracy – One Brain Box at a Time"}
            imgPath={imgPath}
          />
          <Section11 url={devUrl} />
        </article>
      </main>
    </>
  );
};

export default React.memo(AboutMobile);
