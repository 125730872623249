import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "./axios";
import ReCAPTCHA from "react-google-recaptcha";

const Section1 = ({ url }) => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [fname, setFname] = useState("");
  const [validFname, setValidFname] = useState("");

  const [lname, setLname] = useState("");
  const [validLname, setValidLname] = useState("");

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState("");

  const [phoneNo, setPhoneNo] = useState("");
  const [validPhoneNo, setValidPhoneNo] = useState("");

  const [address, setAddress] = useState("");
  const [validAddress, setValidAddress] = useState("");

  const [city, setCity] = useState("");
  const [validCity, setValidCity] = useState("");

  const [region, setRegion] = useState("");
  const [validRegion, setValidRegion] = useState("");

  const [country, setCountry] = useState("");
  const [validCountry, setValidCountry] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [validZipCode, setValidZipCode] = useState("");

  const [message, setMessage] = useState("");
  const [validMessage, setValidMessage] = useState("");

  const navigate = useNavigate();

  const emailApiUrl = "/email.php";

  const formSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      const response = await axios.post(
        emailApiUrl,
        {
          firstName: fname,
          lastName: lname,
          email: email,
          contact: phoneNo,
          address: address,
          city: city,
          state: region,
          country: country,
          zip: zipCode,
          message: message,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.response_code === 200) {
        setSuccess(true);
        setLoader(false);
        setResponseMessage("Request Submitted Successfully !");

        setTimeout(() => {
          setSuccess(false);
          navigate("/thankyou");
        }, 2000);
        // console.log('request Sent');
        // console.log(response);
      } else {
        setSuccess(true);
        setLoader(false);
        setResponseMessage("Failed to Submit Request !");
        // console.log('request inner error');
        // console.log(response);
      }
    } catch (err) {
      setSuccess(true);
      setLoader(false);
      setResponseMessage("Failed to Submit Request !");
      // console.log('request outer error');
    }

    //empty all the fields when submit button is clicked
    setFname("");
    setLname("");
    setEmail("");
    setPhoneNo("");
    setAddress("");
    setCity("");
    setRegion("");
    setCountry("");
    setZipCode("");
    setMessage("");
  };

  //make captcha mandatory

  const [isVerified, setIsVerified] = useState(false);
  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true);
    }
  };

  return (
    <section
      className="section1"
      style={{ backgroundImage: "url(" + url + "images/quote/form-bg.png)" }}
    >
      <div className="container-fluid">
        <div className="row m-0 p-0">
          <div className="col-12 m-0 p-0 wrapper1">
            {loader ? (
              <>
                <h1>Submitting ...</h1>
              </>
            ) : success ? (
              <>
                <h1>{responseMessage}</h1>
                {/* <Link to='/quote' className="backLink" onClick={()=>{setSuccess(false);setResponseMessage('')}} > <i className="fa-solid fa-angles-left"></i> Back to Form </Link> */}
              </>
            ) : (
              <form onSubmit={formSubmit}>
                <div className="row m-0 p-0">
                  <div className="col-12 col-md-6">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputFirstName"
                        className="form-label"
                      >
                        First Name
                      </label>
                      {/* <span id="emailHelp" className="form-text text-danger font-weight-bold ml-3" style={{ display: validFname ? 'inline-block' : 'none' }}><i className="fa-solid fa-triangle-exclamation pr-5"></i> Enter your First Name !</span> */}
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputFirstName"
                        aria-describedby="firstnameHelp"
                        onInvalid={(e) => {
                          setValidFname(e.target.validationMessage);
                        }}
                        required
                        onChange={(e) => {
                          setFname(e.target.value);
                          setValidFname("");
                        }}
                      />

                      <div
                        id="firstnameHelp"
                        className="form-text text-danger"
                        style={{
                          display: validFname !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validFname}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputLastName"
                        className="form-label"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputLastName"
                        aria-describedby="lastnameHelp"
                        onInvalid={(e) => {
                          setValidLname(e.target.validationMessage);
                        }}
                        required
                        onChange={(e) => {
                          setLname(e.target.value);
                          setValidLname("");
                        }}
                      />

                      <div
                        id="lastnameHelp"
                        className="form-text text-danger"
                        style={{
                          display: validLname !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validLname}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="m-3">
                      <label htmlFor="exampleInputEmail" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail"
                        aria-describedby="emailHelp"
                        onInvalid={(e) => {
                          setValidEmail(e.target.validationMessage);
                        }}
                        required
                        placeholder="xyz@gmail.com"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setValidEmail("");
                        }}
                      />

                      <div
                        id="emailHelp"
                        className="form-text text-danger"
                        style={{
                          display: validEmail !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validEmail}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputPhoneNumber"
                        className="form-label"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="exampleInputPhoneNumber"
                        aria-describedby="phonenumberHelp"
                        onInvalid={(e) => {
                          setValidPhoneNo(e.target.validationMessage);
                        }}
                        required
                        maxlength="10"
                        placeholder="3XXXXXXXXX"
                        pattern="[0-9]{10}"
                        onChange={(e) => {
                          setPhoneNo(e.target.value);
                          setValidPhoneNo("");
                        }}
                      />

                      <div
                        id="phonenumberHelp"
                        className="form-text text-danger"
                        style={{
                          display: validPhoneNo !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validPhoneNo}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputStreetAddress"
                        className="form-label"
                      >
                        Street Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputStreetAddress"
                        aria-describedby="streetaddressHelp"
                        onInvalid={(e) => {
                          setValidAddress(e.target.validationMessage);
                        }}
                        required
                        onChange={(e) => {
                          setAddress(e.target.value);
                          setValidAddress("");
                        }}
                      />

                      <div
                        id="streetaddressHelp"
                        className="form-text text-danger"
                        style={{
                          display: validAddress !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validAddress}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="m-3">
                      <label htmlFor="exampleInputCity" className="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputCity"
                        aria-describedby="cityHelp"
                        onInvalid={(e) => {
                          setValidCity(e.target.validationMessage);
                        }}
                        required
                        onChange={(e) => {
                          setCity(e.target.value);
                          setValidCity("");
                        }}
                      />

                      <div
                        id="cityHelp"
                        className="form-text text-danger"
                        style={{
                          display: validCity !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validCity}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputStateRegion"
                        className="form-label"
                      >
                        State/Region
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputStateRegion"
                        aria-describedby="stateregionHelp"
                        onInvalid={(e) => {
                          setValidRegion(e.target.validationMessage);
                        }}
                        onChange={(e) => {
                          setRegion(e.target.value);
                          setValidRegion();
                        }}
                        required
                      />

                      <div
                        id="stateregionHelp"
                        className="form-text text-danger"
                        style={{
                          display: validRegion !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validRegion}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputCountry"
                        className="form-label"
                      >
                        Country
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputCountry"
                        aria-describedby="countryHelp"
                        onInvalid={(e) => {
                          setValidCountry(e.target.validationMessage);
                        }}
                        onChange={(e) => {
                          setCountry(e.target.value);
                          setValidCountry();
                        }}
                        required
                      />

                      <div
                        id="countryHelp"
                        className="form-text text-danger"
                        style={{
                          display: validCountry !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validCountry}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-3">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputZipCode"
                        className="form-label"
                      >
                        Zip Code
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputZipCode"
                        aria-describedby="zipcodeHelp"
                        onInvalid={(e) => {
                          setValidZipCode(e.target.validationMessage);
                        }}
                        required
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          setValidZipCode("");
                        }}
                      />

                      <div
                        id="zipcodeHelp"
                        className="form-text text-danger"
                        style={{
                          display: validZipCode !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validZipCode}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="m-3">
                      <label
                        htmlFor="exampleInputMessage"
                        className="form-label"
                      >
                        Message
                      </label>
                      <textarea
                        name="message"
                        className="form-control"
                        id="exampleInputMessage"
                        aria-describedby="messageHelp"
                        onInvalid={(e) => {
                          setValidMessage(e.target.validationMessage);
                        }}
                        cols="30"
                        rows="5"
                        required
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setValidMessage("");
                        }}
                      ></textarea>

                      <div
                        id="zipcodeHelp"
                        className="form-text text-danger"
                        style={{
                          display: validMessage !== "" ? "block" : "none",
                          fontWeight: "bold",
                        }}
                      >
                        {validMessage}
                      </div>
                    </div>
                    <div className="google-captcha">
                      <ReCAPTCHA
                        sitekey="6LewY1glAAAAAHaNouNSFyffCoIwqX2LWJ8h2WCn"
                        onChange={handleRecaptchaChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <div className="mt-5">
                      <button
                        className="btn btn-primary-2"
                        disabled={!isVerified}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
