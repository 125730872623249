import React from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  return (
    <section className="section1 blog-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <img
            src={url + "images/blogs/blog-11.png"}
            className="shadow"
            alt="Delivery man delivering a package"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <div>
            <div className="title" data-aos="fade-up" data-aos-duration="2000">
              <h2 className="blog-title-heading">
                What Is the Punishment for Package Theft? Is This a State
                Felony?
              </h2>
              <p className="blog-title-desc">
                Since the Covid-19 pandemic, there has been a rise in online
                shopping, but a flip side to this rising trend was the incidence
                of theft. More and more customers report package theft, which
                has become a concern for businesses, logistic companies, and the
                customer itself.
                <br />
                {/* <br /> */}
                In 2021 more than 5 million Americans were victims of porch
                piracy, adding to customer stress. Porch pirates stole packages
                containing various products; valuables, groceries, and medicine.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Porch Pirate Act of 2022</h2>
              <p className="blog-title-text">
                In late 2021 it was suggested that the U.S. Congress pass a bill
                regarding porch piracy laws. This bill was later introduced in
                the House in February 2022 under the "{" "}
                <Link
                  to="https://harder.house.gov/media/press-releases/harder-backs-bill-to-fight-porch-pirates#:~:text=The%20Porch%20Pirates%20Act%20of%202022%20would%20apply%20the%20same,non%2DUSPS%20mail%20are%20minimal."
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  Porch Pirates Act of 2022
                </Link>{" "}
                ". While previously the theft of United States Postal Service
                (USPS) mail and packages was considered a state felony, the bill
                amendment now included packages delivered by private and
                commercial carriers like Amazon, FedEx, and UPS to be covered
                for the federal penalty.
                <br />
                <br />
                Penalties were prescribed depending on the value and items of
                the stolen package. According to{" "}
                <Link
                  to="https://www.bainsheldon.com/porch-pirates-what-are-the-penalties-for-package-theft-in-virginia/#:~:text=Stealing%20another%20person's%20mail%2C%20whether,for%20up%20to%20five%20years."
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  Brain Sheldon
                </Link>
                , a stolen package valued at less than $1000 would be petit
                larceny but a value of more than $1000 would be considered grand
                larceny. The penalty for petit larceny can be up to 12 months of
                imprisonment or up to a $2500 fine, while a grand larceny
                penalty can be 1 to 2 years in prison with a $2500 fine. These
                punishments and penalties may vary depending on the value of the
                stolen packages, the past criminal record of the porch pirate,
                and legal action taken by the customer or state where the crime
                is conducted.
                <br />
                <br />
                <h3>Package Theft Punishment in the USA</h3>
                {/* <br /> */}
                <br />
                Since 2019,{" "}
                <Link
                  to="https://www.businessinsider.com/stealing-package-theft-could-soon-be-a-felony-2023-1"
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  8 US States
                </Link>{" "}
                have declared package theft a crime, while other states are
                working towards doing the same.
              </p>
            </div>
            <div
              className="img-div"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <img
                className="infographics"
                src={url + "images/blogs/blog-11-1.png"}
                alt="Package Theft Punishment in the USAy"
                data-aos="zoom-in"
                data-aos-duration="2000"
              />
              <p className="blog-title-text">
                <span className="bullet-title">• Texas:</span> In 2019, package
                theft was declared a crime; its penalty is 6 months to 10 years
                of imprisonment.
                <br />
                <br />
                <span className="bullet-title">• Michigan:</span> Package theft
                is considered a felony if done a second time, with a punishment
                of up to 5 years in prison. <br />
                <br />
                <span className="bullet-title">• Oklahoma:</span> For package
                theft to be considered a felony, the accused must be sentenced
                in 3 cases within 60 days to be charged with up to 5 years in
                prison.
                <br />
                <br />
                <span className="bullet-title">
                  • Tennessee, Arkansas, and Kentucky:
                </span>{" "}
                The punishment for package theft can be up to 5-6 years in
                prison.
                <br />
                <br />
                <span className="bullet-title">• New Jersey:</span> It is
                considered a third-degree crime with up to 5 years of
                imprisonment.
                <br />
                <br />
                <span className="bullet-title">• Georgia:</span> If a thief
                steals 3 packages from one address or more than 10 from three
                addresses, they could be imprisoned for up to five years. <br />
                <br />
                In some US states, package theft comes under shoplifting or
                pickpocketing and can also be convicted. If the value of the
                items pickpocketed is less than $3500, this is considered a
                category C crime, which has imprisonment from 1-5 years with a
                $10,000 fine. If the property's value is $3500 or more, the
                punishment is 1-10 years in prison and a maximum $10,000 fine.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h3>Shoplifting Punishment in the UK</h3>
            </div>
            <div data-aos="fade-in" data-aos-duration="2000">
              <p className="blog-title-text">
                Package theft is known as{" "}
                <Link
                  to="https://www.sentencingcouncil.org.uk/outlines/shoplifting/#:~:text=What%20is%20the%20maximum%20sentence,sentence%20is%20seven%20years'%20custody."
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  shoplifting in the UK
                </Link>
                . The parliament has set a maximum penalty for this offense: if
                the property or good value's less than £200, the conviction is
                confinement of up to 6 months. If the stolen goods are worth
                more than £200, the maximum sentence is up to seven years. The
                imprisonment and penalty depend on whether the crime was
                pre-planned and if any additional harm is caused to the property
                owner.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h3>Package Theft Punishment in Canada</h3>
            </div>
            <div data-aos="fade-in" data-aos-duration="2000">
              <p className="blog-title-text">
                In{" "}
                <Link
                  to="https://www.criminalcodehelp.ca/offences/against-property/mail-theft/#:~:text=QUICK%20TAKEAWAY,and%20altered%20before%20being%20cashed"
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  Canada
                </Link>
                , the accused is charged with porch theft if the stolen package
                is valued at under $5000 with up to 10 years of imprisonment.
                However, if the package value exceeds $5000, they may be
                convicted with more severe charges. If the possessed item is
                opened earlier to see what's inside or if it's damaged, then it
                counts as a separate charge. <br />
                <br />
                Here is how you can{" "}
                <Link
                  to="/blogs/prevent-package-theft"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  stop porch piracy
                </Link>
                . <br />
                <br />
                Despite strict laws and penalties in the US, Canada, and the UK,
                the porch theft rate has persisted over the years. Package theft
                is the highest during the holiday season. Other than relying on
                the administration, law enforcement, or customer support for
                package recovery and the arrest of the porch pirate, customers
                should also try to take self-precautionary measures, such as{" "}
                <Link to="/" data-aos="fade-in" data-aos-duration="2000">
                  secure package delivery boxes
                </Link>
                , security alerts, and surveillance cameras, so that the package
                stays safe until retrieved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
