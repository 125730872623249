import React from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  return (
    <section className="section1 blog-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <img
            src={url + "images/blogs/blog-10.png"}
            className="shadow"
            alt="Porch pirate stealing a package"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Porch piracy is when a package is embezzled after it has been
                delivered to the customer's doorsteps and before they get a
                chance to receive it. Over the years, there has been a rapid
                increase in online shopping trends, but there has also been a
                gradual increase in package theft.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Porch piracy as a case of the US:</h2>
              <p className="blog-title-text">
                The US has the highest rate of porch piracy in the world. As per
                a{" "}
                <Link
                  to="https://www.forbes.com/home-improvement/home-security/worst-states-for-porch-pirates/"
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  Forbes report
                </Link>
                , the US victims' rate in 2022 was around 79%, where 11% of
                victims had at least one package stolen while 22% had three or
                more packages taken away. San Francisco, Los Angles, Fresno,
                Alaska, and Sacramento are at the top of the cities with the
                most stolen packages.
                <br />
                <br />
                There is no set pattern of products that porch pirates are
                likely to go for. They can take away everything from a $5 bar of
                soap to grocery items, medicines, and luxury items! The
                accumulative loss due to porch piracy in the US has amounted to
                more than $19 million in just 2022.
                <br />
                <br />
                Porch pirates mostly go after the packages of more well-known
                companies. 52% of the packages stolen in the US are from Amazon,
                followed by those from the United States Postal Service and
                FedEx parcels.
                <br />
                <br />{" "}
                <Link
                  to="/blogs/best-mailbox-for-packages"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  Click here
                </Link>{" "}
                to check 6 Best Mailboxes for Packages to Prevent Porch Piracy
                in 2023.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Porch piracy in other countries:</h2>
              <p className="blog-title-text">
                <h3>Porch Piracy in the UK:</h3>
                <br />
                Porch pirates are also quite active in the UK. In 2022, a
                UK-based security company,{" "}
                <Link
                  to="https://www.parcelandpostaltechnologyinternational.com/news/approximately-20m-parcels-were-stolen-across-the-uk-in-2022.html"
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  SimpliSafe
                </Link>
                , released the results of a survey they conducted. It showed
                that around 1/3 of the UK's households had experienced porch
                piracy, with their packages stolen right off their porches or
                safe delivery spots. The survey further showed that around 19
                million parcels also went missing by porch pirates.
                <br />
                <br />
                Like the US, the UK needs help tackling this growing porch
                piracy problem. Governments or law enforcement agencies take no
                concrete actions to tackle this issue which means that the
                citizens have to take matters into their own hands. Some
                installed cameras and security systems for preventive purposes,
                but these are of little help.
                <br />
                <br />
                Here are a few{" "}
                <Link
                  to="/blogs/prevent-package-theft"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  tips to prevent package theft
                </Link>{" "}
                .
                <br />
                <br />
                The times when porch pirates are most active in the UK are
                similar to those in the US. Between 9 am and 5 pm, when most
                people are busy with work, school, or out of the house to run
                errands, package theft is most likely to occur. In England, the
                highest rate for porch pirates is in the City of London, while
                the lowest rate is reported to be in Durham.
                <br />
                <br />
                <h3>Porch Piracy in Canada:</h3>
                <br />
                According to{" "}
                <Link
                  to="https://toronto.ctvnews.ca/one-in-four-canadians-have-had-a-package-stolen-by-a-porch-pirate-survey-1.6196871"
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  CTV
                </Link>
                , FedEx Express Canada is concerned about the alarming rise in
                porch piracy during the holiday season. Since the pandemic,
                there has been a persistent rise in the trends for online
                shopping in Canada, but there has also been a rise in cases of
                porch theft. In the last year only, 24% of Canadian online
                shoppers experienced one or more parcels going missing. Ontario,
                on the other hand, has the highest rate of porch theft, which is
                up to 66%. More homeowners in Canada are installing safe
                mailboxes for safer and secured delivery until recovered.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <h2>Porch Piracy in Germany and UAE:</h2>
            </div>
            <div data-aos="fade-in" data-aos-duration="2000">
              <p className="blog-title-text">
                While porch piracy is common in countries like the US, UK, and
                Canada, in countries like Germany, it rarely happens. One
                possible reason for this is the requirement by logistics
                companies to deliver the package only when someone is available
                to sign for and physically receive the package. If no one's
                available at home, they typically take the parcel back or check
                with the neighbors to see if someone will receive it on your
                behalf. <br />
                <br /> In counties such as the UAE, where there is{" "}
                <Link
                  to="https://fotislaw.com/lawtify/theft-under-uae-law/"
                  target="_blank"
                  rel="nofollow"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  zero tolerance
                </Link>{" "}
                for theft-related crimes, porch piracy is non-existent, or there
                is strict punishment with heavy fines and up to 3 years of
                imprisonment.
                <br />
                <br /> To summarize, porch pirates are more than just active in
                the US and other areas like the UK and Canada. In this case, a
                customer can look for more smart solutions for themselves, such
                as continuously tracking their packages, scheduling the delivery
                time so the package isn't left unattended, or opting for Secure
                Package Delivery Box such as{" "}
                <Link to="/" data-aos="fade-in" data-aos-duration="2000">
                  Brain Box
                </Link>{" "}
                for safer unguarded deliveries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
