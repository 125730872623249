import React from "react";
import { Link } from "react-router-dom";

const Section6 = ({ url }) => {
  return (
    <>
      <section
        className="section-6 hero-banner--two-col-style-5"
        style={{
          backgroundImage: "url(./images/home/home-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 box1">
              <h3 
              style={{
                fontSize: "1.5rem",
              }}
              >Testimonials</h3>
              <p>
              Think Package Safety; Think Brain Box. Users describe how Brain Box has redefined the online shopping experience by alleviating the stress associated with package safe-keeping.
              </p>
              <Link to="/testimonials" className="btn btn-primary-2">
                More
              </Link>
            </div>
            <div className="col-xl-6 box2">
              <div
                className="img-box"
                style={{
                  backgroundImage: "url(./images/home/testimonials.jpg)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Section6);
