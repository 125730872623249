import React from "react";
import "./Thankyou.scss";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  return (
    <section className="section1 thankyou-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <h6
            className="thankyou-text"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            Thank you for reaching out to us. We will get back to you soon.
          </h6>
        </div>
        <div className="thankyou-btn">
          <Link
            to="/"
            className="btn sec3-btn-green mt-3"
            data-aos="fade-right"
            data-aos-duration="2000"
          >
            Home
          </Link>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);