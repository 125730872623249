import React from "react";
// import Banner2 from "../partials/Banner/Banner2";
import Banner3 from "../partials/Banner/Banner3";
import Section1 from "../includes/Contact/Section1";
import { Helmet } from "react-helmet";

const Contact = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/contact/contact-banner.png)";
  const bgColor = "transparent";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <link rel="canonical" href="https://brainbox.company/contact-us" />      
      </Helmet>
      <main>
        <article className="contact">
          {/* <Banner2 text={'Contact Us'} imgPath={imgPath} /> */}
          <Banner3
            text1={"Contact Us"}
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
          />
          <Section1 url={devUrl} />
        </article>
      </main>
    </>
  );
};

export default React.memo(Contact);
