import React from "react";
import "./Sensor2.scss";

const Sensor2 = () => {
  return (
    <>
      <div className="container sensor-2">
        <div className="row">
          <div className="col-xl-12">
            <div className="main-wrapper">
              <img
                src="./images/how-it-works/sensor-image-raw.png"
                alt=""
                data-aos="zoom-in"
                data-aos-duration="2000"
              ></img>
              <div className="text-1">
                <p>
                  Neutral Compartment
                  <br></br>
                  Appropriate Sensors
                </p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="text-2">
                <p>WiFi Connectivity</p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="text-3">
                <p>Dial Pad Option</p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="text-4">
                <p>Date & Time</p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="text-5">
                <p>Reload Option</p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="text-6">
                <p>Device Configuration</p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="d-none d-xl-block text-7">
                <p>
                  Hot Compartment with
                  <br></br>
                  Temperature and Humidity Sensor
                </p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="d-none d-xl-block text-8">
                <p>
                  Cold Compartment with
                  <br></br>
                  Temperature and Humidity Sensor
                </p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
              <div className="d-block d-xl-none text-9">
                <p>
                  Hot & Cold Compartment with
                  <br></br>
                  Temprature and Humidity Center
                </p>
                <div className="line-wrapper">
                  <div className="line">
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Sensor2);
