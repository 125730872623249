import React from "react";
// ..............................
const Section12 = ({ url }) => {
  return (
    <section className="section12">
      <div className="container container1">
        <div className="row gy-5 w-100 wrapper">
          <div className="col-lg-7 col-md-12 col-sm-12 d-flex flex-column justify-content-center order-2 order-md-2 order-sm-2  content"
            data-aos="fade-left"
            data-aos-duration="2000"
          >
            <h3>Single-Person Living Situation</h3>
            <p>
              A student moved to one of the townhouses across campus and
              required a secure solution to receive her deliveries for her while
              she was away at class. <br />
              <br />
              <em>
                “I recently moved off-campus to a smaller studio apartment.
                Online shopping had become somewhat of a guilty pleasure of mine
                during the pandemic, and now I simply do not have enough time to
                collect my things in person. The mail gets redirected to the box
                outside, but I needed a more secure solution to keep my packages
                safe in my absence.
                <br />
                <br />
                I ordered a birthday present for a friend of mine and the
                delivery guy called me while I was in class. I had to ask him to
                leave it outside since none of my friends were answering their
                phones either. Fingers crossed, I ran back after class to
                collect my package and came back to an empty porch.
                <br />
                <br />
                That was when Brain Box came into my life as a beacon of hope.
                Now I just tap my fingers and order away to my heart's content!
                I don’t have to worry about someone stealing my packages because
                Brain Box keeps them locked up and safe until I return! My
                groceries are fresh and my shopping is secure.
                <br />
                <br />
                It is truly one of the best online investments I have made, and
                I am satisfied with the product!”
              </em>
            </p>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 d-flex flex .align-items-center justify-content-center  order-1 order-md-1 order-sm-1">
            <div className="img-boxx">
              <img
                src={url + "images/bussiness.jpg"}
                className="shadow"
                alt=""
                data-aos="fade-right"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container container1">
        <div className="row gy-5 w-100 wrapper">
          <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center  order-lg-2 order-md-1 order-sm-1">
            <div className="img-box">
              <img
                src={url + "images/looking.jpg"}
                alt=""
                className=""
                data-aos="fade-left"
                data-aos-duration="2000"
              />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12 content d-flex flex-column justify-content-center order-lg-1 order-md-2 order-sm-2"
            data-aos="fade-right"
            data-aos-duration="2000"
            >
              <h3>Couple in an Apartment</h3>
              <p>
                A young couple moved to an apartment building in the heart of
                the city and were frequent online grocery shoppers in desperate
                need of a safe alternative to receiving their items safe while
                they were away at work.
                <br />
                <br />
                <em>
                  “John and I barely have time. Between his job and my
                  residency, we can’t even carve out a moment to breathe, which
                  is why we pre-plan. Our grocery deliveries are always lined up
                  to suit our schedules, but the local store tends to bring them
                  either before or after the allocated slot.
                  <br />
                  <br />
                  Often our milk gets spoiled and our groceries go rotten. When
                  we don’t have time to go out and eat ice cream, we order in
                  our pints. Once we came home to a liquified chocolate mess.
                  Brain Box not only gave us the perfect solution to keep our
                  groceries fresh, but alleviated our stress as well! It has
                  separate compartments for hot and cold products, which works
                  well in our favor! I am so glad I saw an ad for it!”
                </em>
              </p>
          </div>
        </div>
      </div>

      <div className="container container1 mb-5 pb-5">
        <div className="row  gy-5 w-100 wrapper">
          <div className="col-lg-7 col-md-12 col-sm-12 content d-flex flex-column justify-content-center  order-2 order-md-2 order-sm-2"
            data-aos="fade-left"
            data-aos-duration="2000"
            >
            <h3>House in a Remote Location</h3>
            <p>
              A family moved to a remote, mountainous location and found it
              difficult to receive secure package deliveries. <br />
              <br />
              <em>
                “My wife and I had set our hearts on the house up here in the
                mountains. It suited our requirements perfectly. The only issue
                was receiving parcels.
                <br />
                <br />
                We would often forget to pick up a few items during our grocery
                runs and it was difficult to go all the way back just for a loaf
                of bread, but we have three adolescent boys – a lot of hungry
                mouths to feed. Getting it delivered seemed like a good option.
                It was tiring to go to our local post office to pick up our
                packages, and when we had them delivered to our house, they
                would get stolen.
                <br />
                <br />
                We needed an urgent solution, and the best one came through
                Brain Box. No more stolen packages! We don't have to deal with
                angry kids by the time we get back, which is an achievement. In
                case we're busy, they have access to the Box too. It is one of
                my best investments.”
              </em>
            </p>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center  order-1 order-md-1 order-sm-1 ">
            <div className="img-box">
              <img
                src={url + "images/sofa.jpg"}
                className="shadow"
                alt=""
                style={{ transform: "scaleX(-1)"}}
                data-aos="fade-right"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section12);