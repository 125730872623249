import React from "react";
import { useContext } from "react";
import Section1 from "../includes/Home/Section1";
import Section2 from "../includes/Home/Section2";
import Section3 from "../includes/Home/Section3";
import Section5 from "../includes/Home/Section5";
import Section6 from "../includes/Home/Section6";
import Section7 from "../includes/Home/Section7";
import HomeOverlay from "./HomeOverlay";
import { GlobalContext } from "../Context/GlobalContext";
import { Helmet } from "react-helmet";

const Home = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/",
    },
    headline: "Prevent Package Theft with Smart & Secure Package Delivery Box",
    image: "https://brainbox.company/images/logo-b.png",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2022-03-01",
    dateModified: "2022-03-02",
  };

  const { show, setShow } = useContext(GlobalContext);
  const switchDisplay = (name) => {
    setShow(name);
  };

  return (
    <>
      <Helmet>
        <title>
          Prevent Package Theft with Smart & Secure Package Delivery Box
        </title>
        <meta
          name="description"
          content="Prevent package theft from porch pirates with Brain Box, smart & secure package delivery box, helps you to receive & store the parcel in respective compartments"
        />
        <link rel="canonical" href="https://brainbox.company/" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main>
        {show === false ? (
          <HomeOverlay devUrl={devUrl} switchDisplay={switchDisplay} />
        ) : (
          <article className="home">
            <Section1 url={devUrl} />
            <Section2 url={devUrl} />
            <Section3 url={devUrl} />
            <Section5 url={devUrl} />
            <Section6 url={devUrl} />
            <Section7 url={devUrl} />
          </article>
        )}
      </main>
    </>
  );
};

export default React.memo(Home);
