import React from "react";
import { Link } from "react-router-dom";
import BlogCard from "../../partials/Cards/BlogCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section7 = ({ url }) => {
  // function for carousel scroll

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="section-7">
        {/* <hr /> */}
        <div className="container blog-section">
          <div
            className="blog-section-title"
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            <p>Blogs</p>
          </div>

          <div
            className="blog-carousel box1"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <Slider {...settings}>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-1.jpeg"}
                  alttxt="What to do when package is stolen?"
                  bloglink="blogs/when-package-is-stolen"
                  blogtitle="What to do when a package is stolen – Report?"
                  blogtext="Here are some preventive measures one should take when a package is stolen."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-2.jpg"}
                  alttxt="How to stop porch piracy"
                  bloglink="blogs/prevent-package-theft"
                  blogtitle="How to prevent package theft?"
                  blogtext="Package theft is a growing problem everywhere, with thieves targeting leftover packages."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-3.jpeg"}
                  alttxt="DIY ideas for the package drop box"
                  bloglink="blogs/diy-ideas-of-package-drop-box"
                  blogtitle="Top 5 DIY ideas for the package drop box"
                  blogtext="Deter porch pirates with these do-it-yourself ideas for the package drop box and secure your parcels."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-4.png"}
                  alttxt="Porch piracy is on the rise."
                  bloglink="blogs/stolen-packages-trend-in-usa"
                  blogtitle="Current trends in the US for stolen packages"
                  blogtext="The porch piracy trend in the USA is a growing problem."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-5.png"}
                  alttxt="Brain Box – A Smart Delivery Box"
                  bloglink="blogs/smart-delivery-box"
                  blogtitle="What makes Brain Box a smart delivery box?"
                  blogtext="A smart delivery box that integrates cutting-edge technology to provide its users."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-6.png"}
                  alttxt="Porch pirate stole your package. How?"
                  bloglink="blogs/when-porch-pirates-strike"
                  blogtitle="Porch pirate stole your package. Here is how?"
                  blogtext="Porch piracy can occur at any time of the day, but there are also some certain hours."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-7.png"}
                  alttxt="Why has the Porch Become an Important Part of Home Security?"
                  bloglink="blogs/porch-in-home-security"
                  blogtitle="Why has the porch become important for homes?"
                  blogtext="The porch has become integral to home security for several reasons."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-8.png"}
                  alttxt="Role of cameras in preventing porch piracy"
                  bloglink="blogs/cameras-does-not-porch-piracy"
                  blogtitle="Are cameras ineffective in stopping porch pirates?"
                  blogtext="Cameras are good deterrents for porch pirates. However, they can't prevent it."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-9.png"}
                  alttxt="6 Best mailbox to prevent porch piracy in 2023"
                  bloglink="blogs/best-mailbox-for-packages"
                  blogtitle="6 Best mailbox to prevent porch piracy in 2023?"
                  blogtext="Here is a list of 6 mailboxes to prevent parcels from porch piracy."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-10.png"}
                  alttxt="Porch pirate stealing a package"
                  bloglink="blogs/porch-piracy-in-the-world"
                  blogtitle="Is porch piracy just a thing in the US?"
                  blogtext="People in US, UK and Canada are losing their packages to porch pirates. Especially during the holiday season."
                />
              </div>
              <div>
                <BlogCard
                  imgsrc={url + "images/blogs/blog-11.png"}
                  alttxt="Delivery man delivering a package"
                  bloglink="blogs/package-theft-punishments"
                  blogtitle="Porch piracy is a state felony"
                  blogtext="Bills such as the 'Porch Pirates Act of 2022' have recently been passed in the US to declare package theft as a felony."
                />
              </div>
            </Slider>
          </div>
          <div className="col-12 text-center">
            <div className="mt-4" data-aos="fade-in" data-aos-duration="2000">
              <Link to="/blogs" className="btn btn-primary-2">
                Blogs
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Section7);
