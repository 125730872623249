import React from "react";

const Section11 = ({ url }) => {
  return (
    <section className="section11">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-7 wrapper1">
            <p>
              Come rain, hail, or snow – Brain Box keeps your package secure
              with its compartmentalized features.
            </p>
            <p>
              The high-quality materials used in the production of Brain Box
              mean that your house retains its curbside appeal while you operate
              and control it remotely, or in person. Brain Box is spacious
              enough to store large and small packages, and its
              temperature-controlled sensors let you know when the ice cream is
              losing it’s cool.
            </p>
            <p>
              The brand-new iPhone you waited months for, or the ice cream you
              were craving? All gone. Hundreds of dollars up in the air. As it
              is, these circumstances plague the online shopping experience.
              With no place to keep your items safe, you fear porch piracy.
            </p>
            <p>
              During the pandemic, our only consolation came from online
              shopping; through freshly packaged brown boxes at the doorstep. As
              exciting as the concept is, it’s also anxiety-inducing, especially
              because matching schedules with delivery timings proves to be a
              hassle.
            </p>
            <p>
              Pre-pandemic, you either had to race from work to retrieve your
              package or would come home to an empty porch, items stolen.
            </p>
            <p>
              That is the mindset we went in with while creating Brain Box –
              your new, state-of-the-art storage experience!
            </p>
          </div>
          <div className="col-12 m-0 p-0 col-md-5 wrapper2">
            <div className="row m-0 p-0 ">
              <div className="col-12 m-0 p-0 imgBox">
                <img src={url + "images/about4.png"} alt="" />
              </div>
              {/* <div className="col-12 m-0 p-0 imgBox">
                <img src={url + "images/about5.jpg"} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section11);
