import React from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  return (
    <section className="section1 blog-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <img
            src={url + "images/blogs/blog-3.jpeg"}
            className="shadow"
            alt="DIY ideas for the package drop box"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Package drop boxes can be a great addition to any home or
                business, providing a secure and convenient way to receive
                packages when you cannot accept them in person. But
                unfortunately, your packages can be vulnerable to theft and
                vandalism if left outside. However, you can use a few DIY traps
                and ideas to keep your parcels secure and safe.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                It is important to note that this is not a substitute for
                professional security measures. Using a{" "}
                <Link to="/" data-aos="fade-in" data-aos-duration="2000">
                  secure and smart package delivery box
                </Link>{" "}
                to receive online orders would be ideal. Such secure delivery
                boxes have multiple compartments that monitor temperature and
                are weather-proof.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Installing a security camera near the drop-off area is one
                simple and effective way to deter theft. You can keep your
                package safe by attaching a small, battery-operated camera
                nearby and ensuring it is in a position that allows it to record
                any activity, including the approach of potential thieves.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Another idea is to install a motion sensor light near the
                package drop area. The light will be activated when someone
                approaches, making it difficult for thieves to remain
                undetected. Installing a solar-powered motion sensor light is
                easy to set up and requires no wiring.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Another option is to install an alarm on the ordinary drop box
                by attaching a small, battery-operated alarm. Then, when the
                door is opened, the alarm will sound, alerting you or a neighbor
                to any potential theft.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Another option is to install a lock on the ordinary drop box.
                Although attaching a small padlock to the door of the drop box
                may act as a deterrent and will prevent anyone from opening the
                drop box without the key, it is by no means the most secure
                option. Using a padlock is like adding an extra layer of
                security to your package drop box.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Finally, you can also consider installing a fake camera or alarm
                system by attaching a fake security camera or alarm sign to the
                drop box. While this won't protect your packages, it can deter
                thieves from attempting to steal from the drop box, as they may
                believe it is constantly being monitored.
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                The use of glitter bombs is becoming more common now to combat
                porch piracy. Decoy packages are kept on the doorstep to lure
                potential porch pirates into taking a chance at swiping your
                parcel. Once a parcel is moved, it explodes to release adhesive
                glitter that sticks to the robber or the getaway vehicle while
                alerting the owner of the theft and the box's location. This
                helps dissuade potential thieves from trying their luck at your
                address and move to 'greener' and 'safer' pastures.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="2000">
              <p className="blog-title-text">
                Here are some DIY ideas for building a package drop box to deter
                package thieves: <br />
                <br />
                1. Build a locking mailbox: You can purchase a locking mailbox
                or build one using a wooden box and a lock. This will provide a
                secure place for your packages to be delivered and deter thieves
                from taking them.
                <br />
                <br /> 2. Create a decoy package: You can create a decoy package
                using an empty box, wrapping paper, and a fake label. Place it
                in a visible location like your front porch to deter thieves
                from targeting your real packages.
                <br />
                <br /> 3. Install a security camera: By installing a security
                camera pointing towards your porch, you can deter thieves from
                stealing packages and have footage to use if theft occurs.
                <br />
                <br /> 4. Use a parcel drop box: You can purchase a parcel drop
                box, a secure container mounted on a wall or fence, or placed
                outside your home. It can be locked, so only you can access the
                packages inside.
                <br />
                <br /> 5. Build a package trap: You can create a package trap by
                building a wooden box with a false bottom. A loud alarm will
                sound when a thief takes the package from the top, and a locking
                mechanism will activate, trapping the thief inside.
              </p>
            </div>
            <div>
              <p className="blog-title-text">
                Check out some additional measures to{" "}
                <Link
                  to="/blogs/prevent-package-theft"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  prevent package theft
                </Link>
                .
              </p>
              <p
                className="blog-title-text"
                data-aos="fade-in"
                data-aos-duration="2000"
              >
                Many DIY options are available to protect your package drop box
                from theft and vandalism. For example, you can ensure that your
                package drop box remains safe and secure by using a combination
                of security cameras, motion sensor lights, door alarms, locks,
                and fake security systems. While these ideas can be effective in
                deterring package theft, they may not always be successful.
                Still, these DIY traps and ideas can help to deter potential
                thieves and provide an extra layer of security for your package
                drop box. It's always best to contact the carrier or shipper,
                and also the police, in the event of a theft.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
