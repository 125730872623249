import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import GlobalContextProvider from './components/Context/GlobalContext';

ReactDOM.render(
  <React.StrictMode>
  <GlobalContextProvider>
    <App />
  </GlobalContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
