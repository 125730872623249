import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/Thankyou/Section1";
import { Helmet } from "react-helmet";

const Thankyou = ({ devUrl }) => {

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Thankyou</title>
        <link rel="canonical" href="https://brainbox.company/thank-you" />
      </Helmet>
      <main>
        <article className="our-team">
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"Thank you"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(Thankyou);