import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/partials/ScrollToTop";
import Navbar from "./components/partials/Navbar/Navbar";
import HomeOverlay from "./components/pages/HomeOverlay";
import Home from "./components/pages/Home";
import HowItWorks from "./components/pages/HowItWorks";
import About from "./components/pages/About";
import AboutMobile from "./components/pages/AboutMobile";
import PorchPiracy from "./components/pages/PorchPiracy";
import Testimonials from "./components/pages/Testimonials";
import Quote from "./components/pages/Quote";
import Contact from "./components/pages/Contact";
import Blogs from "./components/pages/Blogs";
import WhenPackageIsStolen from "./components/pages/WhenPackageIsStolen";
import PorchPiracyAGlobalIssue from "./components/pages/PorchPiracyAGlobalIssue";
import PreventPackageTheft from "./components/pages/PreventPackageTheft";
import PorchPiracyAStateFelony from "./components/pages/PorchPiracyAStateFelony";
import BestDeliveryBox from "./components/pages/BestDeliveryBox";
import DIYIdeasForPackage from "./components/pages/DIYIdeasForPackage";
import StolenPackageTrend from "./components/pages/StolenPackageTrend";
import SmartDeliveryBox from "./components/pages/SmartDeliveryBox";
import WhenPorchPiratesStrike from "./components/pages/WhenPorchPiratesStrike";
import PorchInHomeSecurity from "./components/pages/PorchInHomeSecurity";
import CamerasAreNotEffective from "./components/pages/CamerasAreNotEffective";
import Footer from "./components/partials/Footer/Footer";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import OurTeam from "./components/pages/OurTeam";
import Thankyou from "./components/pages/Thankyou";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({
  once: true, // this one if you want to load animation once else you can remove it
});

const App = () => {
  const currentPath = window.location.pathname;

  var devUrl = "https://brainbox.company/";
  // var devUrl = "http://developers.exdnow.com:8080/brainbox-website-new/";
  // var devUrl = "http://localhost:3000/";

  return (
    // <Router basename="/brainbox-website-new" >
    <Router basename="/">
      <ScrollToTop />

      {currentPath === "/aboutMobile" ? (
        <Routes>
          <Route
            path="/aboutMobile"
            element={<AboutMobile devUrl={devUrl} />}
          />
        </Routes>
      ) : (
        <div className="body">
          <Navbar />

          <div className="wrap">
            <Routes>
              <Route path="/" exact element={<Home devUrl={devUrl} />} />
              <Route path="/home" exact element={<Home devUrl={devUrl} />} />
              <Route path="/about-us" element={<About devUrl={devUrl} />} />
              <Route
                path="/how-it-works"
                element={<HowItWorks devUrl={devUrl} />}
              />
              <Route
                path="/porch-piracy"
                element={<PorchPiracy devUrl={devUrl} />}
              />
              <Route
                path="/testimonials"
                element={<Testimonials devUrl={devUrl} />}
              />
              <Route path="/quote" element={<Quote devUrl={devUrl} />} />
              <Route path="/blogs" element={<Blogs devUrl={devUrl} />} />
              <Route
                path="/blogs/when-package-is-stolen"
                element={<WhenPackageIsStolen devUrl={devUrl} />}
              />
              <Route
                path="/blogs/prevent-package-theft"
                element={<PreventPackageTheft devUrl={devUrl} />}
              />
              <Route
                path="/blogs/diy-ideas-of-package-drop-box"
                element={<DIYIdeasForPackage devUrl={devUrl} />}
              />
              <Route
                path="/blogs/stolen-packages-trend-in-usa"
                element={<StolenPackageTrend devUrl={devUrl} />}
              />
              <Route
                path="/blogs/smart-delivery-box"
                element={<SmartDeliveryBox devUrl={devUrl} />}
              />
              <Route
                path="/blogs/porch-piracy-in-the-world"
                element={<PorchPiracyAGlobalIssue devUrl={devUrl} />}
              />
              <Route
                path="/blogs/when-porch-pirates-strike"
                element={<WhenPorchPiratesStrike devUrl={devUrl} />}
              />
              <Route
                path="/blogs/porch-in-home-security"
                element={<PorchInHomeSecurity devUrl={devUrl} />}
              />
              <Route
                path="/blogs/best-mailbox-for-packages"
                element={<BestDeliveryBox devUrl={devUrl} />}
              />
              <Route
                path="/blogs/cameras-does-not-porch-piracy"
                element={<CamerasAreNotEffective devUrl={devUrl} />}
              />
              <Route
                path="/blogs/package-theft-punishments"
                element={<PorchPiracyAStateFelony devUrl={devUrl} />}
              />
              <Route path="/contact-us" element={<Contact devUrl={devUrl} />} />
              <Route
                path="/privacy-policy"
                element={<PrivacyPolicy devUrl={devUrl} />}
              />
              <Route path="/our-team" element={<OurTeam devUrl={devUrl} />} />
              <Route path="/thankyou" element={<Thankyou devUrl={devUrl} />} />
              <Route
                path="/homeoverlay"
                element={<HomeOverlay devUrl={devUrl} />}
              />
            </Routes>
          </div>

          <Footer />
        </div>
      )}
    </Router>
  );
};

export default App;
