import React from "react";
import Banner2 from "../partials/Banner/Banner2";
import Section1 from "../includes/PrivacyPolicy/Section1";
import { Helmet } from "react-helmet";

const PrivacyPolicy = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/privacy-banner.jpg)";

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <link rel="canonical" href="https://brainbox.company/privacy-policy" />
      </Helmet>
      <main>
        <article className="privacyPolicy">
          <Banner2 text={"Privacy Policy"} imgPath={imgPath} />
          <Section1 url={devUrl} />
        </article>
      </main>
    </>
  );
};

export default React.memo(PrivacyPolicy);
