import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <hr></hr>
      <div className="border-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <img src="./images/logo-b.png" alt="" width={"140px"} />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 p-0 m-0 sec-1">
              <div className="row p-0 m-0">
                <div className="col-xl-6 m-0 wrapper">
                  <div>
                    <p>Company</p>
                    <ul>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/how-it-works">How it Works</Link>
                      </li>
                      <li>
                        <Link to="/quote">Quote</Link>
                      </li>
                      <li>
                        <Link to="/blogs">Blogs</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 m-0 wrapper">
                  <div>
                    <p>Support</p>
                    <ul>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/testimonials">Reviews</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 m-0 sec-2">
              <div className="sec-2-wrapper">
                <p>
                  Get updates for secure package delivery box in your inbox.
                </p>

                <form>
                  <div className="row g-0">
                    <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 pe-sm-0">
                      <label htmlFor="leadEmail" className="form-label d-none">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="leadEmail"
                        placeholder="Enter email address"
                      ></input>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-2 ps-sm-2">
                      <button type="submit" className="btn btn-primary-2">
                        Done
                      </button>
                    </div>
                  </div>
                </form>

                <div className="social-list">
                  <a
                    href="https://www.facebook.com/brainbox472"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/brainbox37/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://twitter.com/BrainBox393"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/brain-box1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
