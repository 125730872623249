import React from "react";
import { Link } from "react-router-dom";
import "./BestDeliveryBox.scss";

const Section2 = ({ url }) => {
  return (
    <>
      <div className="blog-9-section-2">
        <div className="container blog-9-container1">
          <div className="row">
            <div
              className="col-xl-6 blog-9-box-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h2>Brain Box</h2>
              <p>
                The Brain Box is a unique and innovative mailbox designed to
                make package deliveries more secure and convenient. It is a
                smart mailbox with a weather-resistant finish, camera, motion
                sensors, and a mobile app allowing you to monitor deliveries in
                real-time. The Brain Box also has a locking mechanism that can
                be controlled from your phone, so you can ensure your packages
                are safe and secure at all times. The owner is constantly
                updated on the compartment's temperature, so items that need
                cooling are stored in a specific section, and those that need to
                be kept hot are held accordingly.
              </p>
              <div className="col-12 product-link">
                <div className="row">
                  <div className="col-3">
                    <p className="prdct-lnk-text">Product:</p>
                  </div>
                  <div className="col-9 link-btn">
                    <Link
                      to="/quote"
                      target="blank_"
                      rel="nofollow"
                      className="product-btn"
                    >
                      click here!
                    </Link>
                  </div>
                </div>
              </div>
              <p className="prdct-price">Price: Request a quote</p>
            </div>
            <div className="col-xl-6 blog-9-box-2">
              <div className="img-box">
                <img
                  src={url + "images/blogs/blog-9-1.png"}
                  className="blog-9-bb-image"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  alt="Brain Box Hive"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container container2">
          <div className="row">
            <div
              className="col-xl-6 blog-9-box-1 order-1 order-xl-2"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h2>The CleverMade Lockable Parcel Box</h2>
              <p>
                The CleverMade Lockable Parcel Box is an option for anyone who
                frequently receives packages. This heavy-duty aluminum mailbox
                features a digital lockable door that keeps packages secure
                until you can retrieve them. It also has a large capacity and
                ground anchoring system, so you can receive multiple packages
                without worrying about running out of space.
              </p>
              <div className="col-12 product-link">
                <div className="row">
                  <div className="col-3">
                    <p className="prdct-lnk-text">Product:</p>
                  </div>
                  <div className="col-9 link-btn">
                    <a
                      href="https://www.amazon.com/CleverMade-Parcel-LockBox-S100-Construction/dp/B07Y2QPMQF"
                      target="blank_"
                      rel="nofollow"
                      className="product-btn"
                    >
                      click here!
                    </a>
                  </div>
                </div>
              </div>
              <p className="prdct-price">Price: $215</p>
            </div>
            <div className="col-xl-6 blog-9-box-2 order-2 order-xl-1">
              <div className="img-box">
                <img
                  src={url + "images/blogs/blog-9-2.png"}
                  className="blog-9-bb-image"
                  alt="The CleverMade Lockable Parcel Box"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container blog-9-container1">
          <div className="row">
            <div
              className="col-xl-6 blog-9-box-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h2>
                Architectural Mailboxes 6900B Elephantrunk Parcel Drop Box
              </h2>
              <p>
                The Architectural Mailboxes 6900B Elephantrunk Parcel Drop Box
                is another option for package deliveries. It is made of durable
                steel and has a weather-resistant finish, making it suitable for
                any climate. The Elephantrunk is also large enough to
                accommodate most packages and has a secure locking mechanism to
                keep your packages safe.
              </p>
              <div className="col-12 product-link">
                <div className="row">
                  <div className="col-3">
                    <p className="prdct-lnk-text">Product:</p>
                  </div>
                  <div className="col-9 link-btn">
                    <a
                      href="https://www.amazon.com/Architectural-Mailboxes-6900B-Elephantrunk-Parcel/dp/B00AESQ1EW?th=1"
                      target="blank_"
                      rel="nofollow"
                      className="product-btn"
                    >
                      click here!
                    </a>
                  </div>
                </div>
              </div>
              <p className="prdct-price">Price: $197</p>
            </div>
            <div className="col-xl-6 blog-9-box-2">
              <div className="img-box">
                <img
                  src={url + "images/blogs/blog-9-3.png"}
                  className="blog-9-bb-image"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  alt="Architectural Mailboxes 6900B Elephantrunk Parcel Drop Box"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container container2">
          <div className="row">
            <div
              className="col-xl-6 blog-9-box-1 order-1 order-xl-2"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h2>dVault Parcel Protector Vault DVU0050</h2>
              <p>
                The dVault Parcel Protector Vault DVU0050 is a high-security
                mailbox that protects your packages from theft and damage. It is
                made of heavy-gauge steel and features a pry-resistant door and
                a tamper-proof locking mechanism. The Parcel Protector Vault
                also has a weather-resistant finish and a large capacity, making
                it suitable for use in any climate.
              </p>
              <div className="col-12 product-link">
                <div className="row">
                  <div className="col-3">
                    <p className="prdct-lnk-text">Product:</p>
                  </div>
                  <div className="col-9 link-btn">
                    <a
                      href="https://www.amazon.com/dVault-Protector-DVU0050-Locking-Mailbox/dp/B003UNWW50?th=1"
                      target="blank_"
                      rel="nofollow"
                      className="product-btn"
                    >
                      click here!
                    </a>
                  </div>
                </div>
              </div>
              <p className="prdct-price">Price: $800</p>
            </div>
            <div className="col-xl-6 blog-9-box-2 order-2 order-xl-1">
              <div className="img-box">
                <img
                  src={url + "images/blogs/blog-9-5.png"}
                  className="blog-9-bb-image"
                  alt="dVault Parcel Protector Vault DVU0050"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container blog-9-container1">
          <div className="row">
            <div
              className="col-xl-6 blog-9-box-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h2>Mail Boss Package Master Curbside Locking Mailbox</h2>
              <p>
                The Mail Boss Package Master Curbside Locking Mailbox is a
                mailbox that is specifically designed to handle large packages.
                It is made of heavy-gauge steel and has a patented anti-pry
                locking mechanism that keeps your packages secure. The Package
                Master also has a powder-coated finish that resists rust and
                weather damage, ensuring your mailbox lasts for years.
              </p>
              <div className="col-12 product-link">
                <div className="row">
                  <div className="col-3">
                    <p className="prdct-lnk-text">Product:</p>
                  </div>
                  <div className="col-9 link-btn">
                    <a
                      href="https://www.amazon.com/Mail-Boss-Package-Security-7206/dp/B00442AYFG?th=1"
                      target="blank_"
                      rel="nofollow"
                      className="product-btn"
                    >
                      click here!
                    </a>
                  </div>
                </div>
              </div>
              <p className="prdct-price">Price: $212</p>
            </div>
            <div className="col-xl-6 blog-9-box-2">
              <div className="img-box">
                <img
                  src={url + "images/blogs/blog-9-6.png"}
                  className="blog-9-bb-image"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  alt="Mail Boss Package Master Curbside Locking Mailbox"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container container2">
          <div className="row">
            <div
              className="col-xl-6 blog-9-box-1 order-1 order-xl-2"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h2>Step2 Lakewood Package Delivery Box</h2>
              <p>
                The Step2 Lakewood Package Delivery Box comes with a chute and a
                one-way internal spring-loaded panel system that allows packages
                to be secured in a compartment accessible by key only. It is
                made of durable plastic and has a large capacity, so you can
                receive multiple packages. The Lakewood Package Delivery Box
                also has a weather-resistant finish and ground anchors.
              </p>
              <div className="col-12 product-link">
                <div className="row">
                  <div className="col-3">
                    <p className="prdct-lnk-text">Product:</p>
                  </div>
                  <div className="col-9 link-btn">
                    <a
                      href="https://www.amazon.com/Step2-Lakewood-Package-Delivery-Box/dp/B0BM56TYC9"
                      target="blank_"
                      rel="nofollow"
                      className="product-btn"
                    >
                      click here!
                    </a>
                  </div>
                </div>
              </div>
              <p className="prdct-price">Price: N/A</p>
            </div>
            <div className="col-xl-6 blog-9-box-2 order-2 order-xl-1">
              <div className="img-box">
                <img
                  src={url + "images/blogs/blog-9-4.png"}
                  className="blog-9-bb-image"
                  alt="Step2 Lakewood Package Delivery Box "
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Section2);
