import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/PorchInHomeSecurity/Section1";
import { Helmet } from "react-helmet";

const PorchInHomeSecurity = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/blogs/porch-in-home-security",
    },
    headline: "Why porch security is important?",
    image: "https://brainbox.company/images/blogs/blog-7.png",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2023-03-28",
    dateModified: "2023-03-29",
  };

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Why porch security is important?</title>
        <meta
          name="description"
          content="The porch has become integral to home security for several reasons including protection from Burglars and many more."
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link
          rel="canonical"
          href="https://brainbox.company/blogs/porch-in-home-security"
        />
      </Helmet>
      <main>
        <article className="our-team">
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"Why has the Porch Become an Important Part of Home Security?"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(PorchInHomeSecurity);
