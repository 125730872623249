import React from "react";
import { Link } from "react-router-dom";

const Section2 = ({ url }) => {
  return (
    <>
      <section className="section-2 hero-banner--two-col-style-4">
        <div className="container container1">
          <div className="row">
            <div
              className="col-xl-6 box1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <h2
              style={{
                fontSize: "1.5rem",
                fontWeight: "700"
              }}
              >Stop Porch Theft with a Smart & <br></br>Secure Package Drop Box </h2>
              <p>
              Statistically speaking, more than 3 in 4 Americans have been a victim of package theft, and more than half of all packages stolen are valued between $50-$200. With Brain Box, you get the cleverest porch piracy solution with temperature control sensors.
              </p>
              <Link to="/porch-piracy" className="btn btn-primary-2">
                More
              </Link>
            </div>
            <div className="col-xl-6 box2">
              <div className="img-box">
                <div className="bb-image-bg"></div>
                <img
                  src={url + "images/home/stop-porch-piracy.jpg"}
                  className="bb-image"
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  alt="Prevent Porch Piracy with Brain Box"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container container2">
          <div className="row">
            <div
              className="col-xl-6 box1 order-1 order-xl-2"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <h2 
              style={{
                fontSize: "1.5rem",
                fontWeight: "700"
              }}
              >UNCOMPLICATED DELIVERY</h2>
              <p>Figuring out if you might be home when a package arrives is such a hassle; carrying packages home from the office or pick-up points provides security, but doesn’t that mean extra work for you? Brain Box takes the complication out of the delivery business as a secure package delivery box.
                <br></br>
                <br></br>
              Hectic schedules and supply-chain uncertainties, added to package theft, mean order deliveries are a complicated business.
              </p>
              <Link to="/porch-piracy" className="btn btn-primary-2">
                More
              </Link>
            </div>
            <div className="col-xl-6 box2 order-2 order-xl-1">
              <div className="img-box">
                <div className="bb-image-bg"></div>
                <img
                  src={url + "images/home/uncomplicated-delivery.jpg"}
                  className="bb-image"
                  alt="Porch piracy solution for porch"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Section2);
