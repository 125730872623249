import React from "react";

const Section1 = ({ url }) => {
  return (
    <section className="section1">
      <div className="container">
        <div className="row">
          <div className="col-12 wrapper1">
            <h6>Email </h6>
            <hr />
            <p>info@brainbox.company</p>
            <br />
            <h6>Address </h6> 
            <hr />
            <p>ExD House, 89-I, Jail Rd, G.O.R. - I, Lahore, 54000</p>
            {/* <br />
            <h6>Phone </h6> 
            <hr />
            <p> (042) 35749430</p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
