import React from "react";
import Section1 from "../includes/PorchPiracy/Section1";
// import Section2 from '../includes/PorchPiracy/Section2';
import Section3 from "../includes/PorchPiracy/Section3";
import Banner from "../partials/Banner/Banner";
import { Helmet } from "react-helmet";

const PorchPiracy = ({ devUrl }) => {
  const imgPath =
    "url(" + devUrl + "images/porch-piracy/porch-piracy-banner.jpg)";

  return (
    <>
      <Helmet>
        <title>Porch Piracy</title>
        <link rel="canonical" href="https://brainbox.company/porch-piracy" />
      </Helmet>
      <main>
        <article className="porch">
          <Banner
            text={"Porch Piracy – A Menace for Online Shoppers"}
            imgPath={imgPath}
          />
          <Section1 url={devUrl} />
          {/* <Section2  url={devUrl} /> */}
          <Section3 url={devUrl} />
        </article>
      </main>
    </>
  );
};

export default React.memo(PorchPiracy);
