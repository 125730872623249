import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/WhenPackageIsStolen/Section1";
import { Helmet } from "react-helmet";

const WhenPackageIsStolen = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/blogs/when-package-is-stolen",
    },
    headline: "What to do when your package is stolen? Can you report it?",
    image: "https://brainbox.company/images/blogs/blog-1.jpeg",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2023-02-13",
    dateModified: "2023-02-14",
  };

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Report Your Stolen Package</title>
        <meta
          name="description"
          content="Here are some preventive measures one should take when a package is stolen from your porch."
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link rel="canonical" href="https://brainbox.company/when-package-is-stolen" />
      </Helmet>
      <main>
        <article className="our-team">
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"What to do when a package is stolen – Report?"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(WhenPackageIsStolen);
