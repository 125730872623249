import React from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  return (
    <section className="section1 blog-title-section">
      <div className="container">
        <div className="col-12 wrapper1">
          <img
            src={url + "images/blogs/blog-4.png"}
            className="shadow"
            alt="Porch piracy is on the rise."
            data-aos="fade-up"
            data-aos-duration="2000"
          />
          <div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                The rise of online shopping in the United States has increased
                package theft, also known as "porch piracy." The number of
                packages stolen by porch pirates in recent years is difficult to
                determine, but estimates suggest it is a significant and growing
                problem. In 2019, 26 million packages were estimated to be
                stolen or lost during transit in the United States alone. More
                recent studies suggest that the problem has only worsened, with
                some estimates putting the number of packages stolen in 2020 as
                high as 36 million.
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                While exact numbers may be challenging to determine, the
                estimates that millions of packages are stolen each year by
                "porch pirates" are based on an increasing number of people
                experiencing theft. This increase is directly proportional to
                online shopping and home deliveries. As a result, it has become
                a growing concern for both individuals and businesses. According
                to a survey conducted by Xfinity, 26 million Americans have had
                a package stolen from their doorstep. The National Retail
                Federation also estimated that over 11 million homeowners had
                packages stolen in 2019. These numbers highlight the widespread
                nature of the problem and the need for practical solutions to
                address it.
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                A study by <a href="https://www.security.org/package-theft/annual-report/" target="blank_" data-aos="fade-in" data-aos-duration="2000">
                Security.org
                </a>{" "}  involving more than
                18,000 adults in all 50 states found that 49 million Americans
                have experienced at least one package being stolen from them in
                one year, with the highest prevalence in Alaska. In addition,
                the average value of stolen merchandise was $50, with
                state-specific averages varying from $30 to $80. The study also
                found that package theft rates remain high, especially when
                heading into the holiday season, with 19% of American adults
                experiencing at least one delivery stolen in the year leading up
                to November 2021. In addition, as more companies return to
                in-person work and more online sale events occur, the porch
                piracy rate will likely continue to rise well into 2022 and
                2023.
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                It can be challenging to determine the worth of a package until
                it is opened, but in some states, package thieves are finding
                more valuable content. However, this varies by state, with
                Alaska and Rhode Island having the highest package theft rates
                and median value of stolen shipments, closely followed by
                Oklahoma. In contrast, Arizona had the lowest median value of
                stolen packages, followed by Illinois, Louisiana, and New
                Jersey.
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                In recent years, the noticeable increase in package theft has
                coincided with reports of stolen packages from peoples'
                doorstep. In addition, the growing popularity of online shopping
                means that more and more packages are being delivered directly
                to homes.
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Several solutions have been proposed to combat this problem,
                including smart locks,{" "}
                <Link to="/" data-aos="fade-in" data-aos-duration="2000">
                  secure package delivery boxes
                </Link>{" "}
                , and in-home delivery systems. Additionally, many shipping
                companies have implemented new security measures, such as
                real-time tracking, signature confirmation, and insurance for
                lost or damaged packages.
              </p>
            </div>
            <div>
              <p className="blog-title-text">
                Here are a few tips and{" "}
                <Link
                  to="/blogs/diy-ideas-of-package-drop-box"
                  data-aos="fade-in"
                  data-aos-duration="2000"
                >
                  DIY ideas to prevent porch piracy
                </Link>.{" "}
              </p>
            </div>
            <div>
              <p
                className="blog-title-text"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                Despite these efforts, package theft continues to be a
                significant issue for many people, particularly during the
                holiday season when the volume of packages delivered is at its
                highest. In some areas, local police departments have even set
                up special operations to catch package thieves in the act.
              </p>
            </div>
            <div>
              <p className="blog-title-text" data-aos="fade-in" data-aos-duration="2000">
              To further address the issue, some communities have formed neighborhood watch groups to keep an eye out for suspicious activity and encourage residents to take steps to protect their packages, such as having them delivered to a workplace or a trusted neighbor or better yet, to have them delivered to secure package delivery boxes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
