import React from "react";
// import { Link } from "react-router-dom";
import "./BestDeliveryBox.scss";

const Section3 = ({ url }) => {
  return (
    <>
      <div
        className="prdct-bottom-text"
        data-aos="fade-in"
        data-aos-duration="2000"
      >
        <div className="bottom-text-body">
          <div className="bottom-text">
            <p>
              In conclusion, many options are available for finding the correct
              mailbox for packages ordered online. These mailboxes are among the
              best for their durability, security, and capacity. Whether you
              choose a heavy-duty and traditional mailbox or a smart
              temperature-controlled one like the Brain Box, designed to handle
              packages, food, and even make-up is essential for anyone who shops
              online frequently.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Section3);
