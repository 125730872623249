import React from "react";
import './bannerFix.css';

const Section1 = ({ url, imgPath }) => {
  return (
    <section className="text-with-bg-style-2"  style={{ backgroundImage: imgPath }}>
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-12 col-sm-12 heading-col">
              <h1
                className="mb-0"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                No
                <br></br>
                Need to
                <br></br>
                Think
              </h1>
              <h1
                className="mb-0"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                Outside
                <br></br>
                the Box
              </h1>
            </div>

            <div className="col-xl-8 col-md-12 col-sm-12">
              <div className="top-banner-content">
                <p className="mb-0">
                  Brain Box provides a secure, weatherproof alternative to manage
                  deliveries conveniently through your phone. No locks or special
                  codes are required – the package is delivered by scanning its
                  barcode to open the appropriate ‘Box compartment to place your
                  package inside.
                  <br></br>
                  <br></br>
                  No need to request for shipments in plain packaging. Brain Box
                  offers a spacious and stylish way to get rid of the last hurdle
                  in online shopping. What is the use of online shopping if the
                  last mile for your package is insecure? The list of worries
                  about package delivery can go on and on unless you’re talking
                  about Brain Box!
                  <br></br>
                  <br></br>
                  Brain Box is so easy to use. In case the product does not
                  feature a barcode, the delivery person can request access to the
                  appropriate storage compartment through Brain Box secure app.
                  The owner receives a notification through the app on their
                  mobile device and can unlock it for the items to be stored
                  safely.
                </p>
              </div>
            </div>


          </div>
        </div>
      </div>

    </section>
  );
};

export default React.memo(Section1);
