import React from "react";

const Section3 = ({ url }) => {
  return (
    <section
      className="section-3"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            {/* <video src={ url +'brainbox.mp4'} autoPlay loop muted /> */}
            <video
              src={url + "images/how-it-works/brainbox.mp4"}
              controls
              poster={url + "images/9.jpg"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section3);
