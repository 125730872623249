import React from "react";

const Section1 = ({ url }) => {
  return (
    <section className="section1 team-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 text-center">
            <div
              className="section-title mx-auto"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              <h1>Our Team</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container" data-aos="fade-up" data-aos-duration="2000">
        <div className="row executives-row mx-auto">
          <div className="col-xl-6">
            <div className="card card-large">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/sajjad.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Sajjad Syed</h5>
                <h6 className="card-subtitle">Founder</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card card-large">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top nailah"
                    style={{
                      backgroundImage: "url(./images/our-team/nailah.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Nailah Syed</h5>
                <h6 className="card-subtitle">Co Founder</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row team-row mx-auto">
          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/zia.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Ziaullah Mughal</h5>
                <h6 className="card-subtitle">Project Manager</h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="card card-medium" style={{ marginTop: "15px" }}>
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/bilal.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Bilal Ikram</h5>
                <h6 className="card-subtitle">Embedded System Engineer</h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/mahnoor.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Mahnoor Tariq</h5>
                <h6 className="card-subtitle">Creative Designer</h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage:
                        "url(./images/our-team/abdul-rehman.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Abdul Rehman</h5>
                <h6 className="card-subtitle">Web Developer</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="row team-row mx-auto">
          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/umar.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">M. Umar Ashraf</h5>
                <h6 className="card-subtitle">Web Developer</h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/sajeel.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">M. Sajeel</h5>
                <h6 className="card-subtitle">Android App Developer</h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage: "url(./images/our-team/ahmad.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Ahmad Ayub</h5>
                <h6 className="card-subtitle">iOS App Developer</h6>
              </div>
            </div>
          </div>

          <div className="col-xl-3">
            <div className="card card-medium">
              <div className="card-img-wrapper">
                <div className="card-img-bg"></div>
                <div className="card-img-top-wrapper">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundImage:
                        "url(./images/our-team/mehboob-ur-rehman.jpg)",
                    }}
                  ></div>
                </div>
              </div>
              <div className="card-body">
                <h5 className="card-title">Mehboob ur Rehman</h5>
                <h6 className="card-subtitle">Digital Marketing Associate</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Section1);
