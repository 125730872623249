import React from "react";

const Banner = ({ text, imgPath }) => {
  return (
    <article className="banner">
      <section
        className="b-section1"
        style={{ backgroundImage: imgPath }}
      >
         <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12"
              data-aos="fade-right"
              data-aos-duration="2000"
              >
              <h3 className="ban-top-text">COMBATING <br /> PORCH <br /> PIRACY <br /> ONE BRAIN <br /> BOX AT A TIME </h3> 
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <img src="images/porch-piracy/box-banner.png" className="ban-top-image" alt=""
                data-aos="fade-left"
                data-aos-duration="2000"
              ></img>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="b-section2">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 wrapper1">
              <h3>{text}</h3>
            </div>
          </div>
        </div>
      </section> */}
    </article>
  );
};

export default Banner;
