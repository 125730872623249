import React from "react";
// import { Link } from "react-router-dom";

const Section2 = ({ url }) => {
  return (
    <section className="section-2 three-col--img-with-text-style-3"
    style={{ backgroundImage: "url(" + url + "./images/how-it-works/bg-sec-3.png)" }}
    >
      <div className="container">
        <div className="row">
          {/* <img
            src="./images/how-it-works/brainbox-logo-light-blue.png"
            className="watermark"
            alt="..."
          ></img> */}
          <div className="col-xl-12 text-center">
            <h1 className="section-title">How It Works</h1>
            <hr></hr>
          </div>
          <div className="col-xl-9 mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 d-grid">
                  <div className="card">
                    <div className="card-img-wrapper">
                      <img
                        src="./images/how-it-works/Step-1.png"
                        className="card-img-top"
                        alt="..."
                      ></img>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Step-1</h5>
                      <p className="card-text">
                        Customer places the order through Eshop
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 d-grid">
                  <div className="card">
                    <div className="card-img-wrapper">
                      <img
                        src="./images/how-it-works/Step-2.png"
                        className="card-img-top"
                        alt="..."
                      ></img>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Step-2</h5>
                      <p className="card-text">
                        Rider arrives with product, scanning command sent
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 d-grid">
                  <div className="card">
                    <div className="card-img-wrapper">
                      <img
                        src="./images/how-it-works/Step-3.png"
                        className="card-img-top"
                        alt="..."
                      ></img>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Step-3</h5>
                      <p className="card-text">
                        Brain Box opens appropriate compartment
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 d-grid">
                  <div className="card">
                    <div className="card-img-wrapper">
                      <img
                        src="./images/how-it-works/Step-4.png"
                        className="card-img-top"
                        alt="..."
                      ></img>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Step-4</h5>
                      <p className="card-text">
                        Owner is notified through Brain Box App
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 d-grid">
                  <div className="card">
                    <div className="card-img-wrapper">
                      <img
                        src="./images/how-it-works/Step-5.png"
                        className="card-img-top"
                        alt="..."
                      ></img>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Step-5</h5>
                      <p className="card-text">Owner unlocks compartment</p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 d-grid">
                  <div className="card">
                    <div className="card-img-wrapper">
                      <img
                        src="./images/how-it-works/Step-6.png"
                        className="card-img-top"
                        alt="..."
                      ></img>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Step-6</h5>
                      <p className="card-text">Owner retrieves package</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container title-with-text-style-1">
        <div className="row">
          <div className="col-xl-12">
            <div className="card c-style">
              <div className="card-body">
                <div className="card-title-wrapper">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Key Features of Brain Box</h4>
                    </div>
                  </div>
                </div>

                <ul>
                  <li>
                    <p>
                      <b>Simple:</b> Brain Box connects to your home WiFi so
                      start shopping at the integrated E-Commerce website,
                      Eshop.
                    </p>
                  </li>

                  <li>
                    <p>
                      <b>Weatherproof:</b> Brain Box is heat, cold, snow, and
                      rain resistant, keeping parcels dry and safe until they
                      are retrieved.
                    </p>
                  </li>

                  <li>
                    <p>
                      <b>Smart:</b> Monitor Brain Box through your phone; you
                      get automatic updates from the Brain Box mobile app,
                      allowing 24/7 control in the palm of your hand.
                    </p>
                  </li>

                  <li>
                    <p>
                      <b>Family-friendly:</b> It can be accessed by all members
                      of the family. If Dad places an order for medicines to be
                      delivered at home, Mom can access Brain Box using her app.
                    </p>
                  </li>

                  <li>
                    <p>
                      <b>Safe:</b> Brain Box allows for safe access. Made from
                      steel and bolted to the porch, Brain Box leaves no
                      opportunities to be stolen or broken through. Its
                      temperature-controlled sensors allow the appropriate
                      compartments to keep the products safe!
                    </p>
                  </li>

                  <li>
                    <p>
                      <b>Time-Effective:</b> Order with ease, without worrying
                      about matching schedules to package delivery timings!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <img src="./images/how-it-works/sec-3.png" alt="" /> */}
    </section>
  );
};

export default React.memo(Section2);
