import React from "react";
import Banner4 from "../partials/Banner/Banner4";
import Section12 from "../includes/Testimonials/Section12";
import { Helmet } from "react-helmet";

const Testimonials = ({ devUrl }) => {
  const imgPath =
    "url(" + devUrl + "images/testimonials/testimonial-banner.jpg)";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Testimonials</title>
        <link rel="canonical" href="https://brainbox.company/testimonials" />
      </Helmet>
      <main>
        <article className="testimonials">
          <Banner4 imgPath={imgPath} minHeight={minHeight} />
          <Section12 url={devUrl} />
          {/* <Banner2 text={'Brain Box Testimonials'} imgPath={imgPath} /> */}
          {/* <Section11/>
                <Section12 url={devUrl} /> */}
        </article>
      </main>
    </>
  );
};

export default React.memo(Testimonials);
