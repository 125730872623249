import React from "react";
import Section1 from "../includes/HomeOverlay/Section1";
import { Helmet } from "react-helmet";

const HomeOverlay = ({ devUrl, switchDisplay }) => {
  return (
    <>
      <Helmet>
        <title>
          Prevent Package Theft with Smart & Secure Package Delivery Box
        </title>
        <meta
          name="description"
          content="Prevent package theft from porch pirates with Brain Box, smart & secure package delivery box, helps you to receive & store the parcel in respective compartments"
        />
        <link rel="canonical" href="https://brainbox.company/" />
      </Helmet>
      <main>
        <article className="home">
          <Section1 url={devUrl} switchDisplay={switchDisplay} />
        </article>
      </main>
    </>
  );
};

export default React.memo(HomeOverlay);
