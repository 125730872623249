import React from "react";
// import { Link } from "react-router-dom";

const Section1 = (props) => {
  var display = props.switchDisplay;

  return (
    <>
      <section
        className="section-overlay"
        style={{
          backgroundImage: "url(./images/homeoverlay/home-bg.png)",
        }}
      >
        <div className="section-1-sub hero-banner--one-col-centered-style-1">
          <div className="wrapper text-center mb-top-margin">
            <div className="bb-circle-gradient"></div>
            <img
              className="mb-bg-net"
              src={props.url + "images/home/home-banner-1.png"}
              alt=""
            ></img>
            <img
              className="d-xl-block mx-auto"
              src={props.url + "images/logo-white.png"}
              alt="Brain Box"
              style={{
                width: "200px",
              }}
            ></img>
            <h1
              className="display-5 mt-5"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              Are You a Victim of{" "}
              <b className="mb-clr-change">
                Package Theft<pre className="mb-qmark">?</pre>
              </b>
            </h1>
            <div className="row mb-t5">
              <div className="col-xl-4 order-2 order-xl-1">
                <h3
                  className="mak_responsive mak_list"
                  data-aos="fade-up-right"
                  data-aos-duration="2000"
                >
                  Secure Your Package
                </h3>
              </div>
              <div className="col-xl-4 order-1 order-xl-2 mb-qmark-1">
                <h3
                  className="q-mark mak_responsive"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  ?
                </h3>
              </div>
              <div className="col-xl-4 order-3 order-xl-3">
                <h3
                  className="mak_responsive mak_list"
                  data-aos="fade-up-left"
                  data-aos-duration="2000"
                >
                  Stop Theft Today
                </h3>
              </div>
              <div className="col-xl-12 order-4">
                <h3
                  className="mak_responsive mak_list"
                  data-aos="zoom-in"
                  data-aos-duration="2000"
                >
                  Weather Resistant Smart Box
                </h3>
              </div>
              <div className="col-lg-6 mx-auto order-5">
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                  <button
                    className="btn btn-primary btn-lg find_solution"
                    style={{
                      marginTop: "30px",
                    }}
                    onClick={() => display(true)}
                  >
                    Solutions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(Section1);
