import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-light fixed`}>
      <Link className="navbar-brand" to="/" onClick={closeMobileMenu}>
        <img
          src={"/images/logo-b.png"}
          alt="Brain Box"
          className="logo-style"
        />
      </Link>
      <div className="menuToggle" onClick={handleClick}>
        <i className={click ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
      </div>
      <div
        className={
          click
            ? "collapse navbar-collapse d-block text-center"
            : "collapse navbar-collapse"
        }
        id="navbarNav"
      >
        <ul className="navbar-nav mx-auto">
          <li>
            <Link
              to="/"
              className="nav-link desktop-item"
              onClick={closeMobileMenu}
            >
              Home
            </Link>
          </li>
          <li className="nav-item nav-link-divider">
            <span>|</span>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link className="nav-link desktop-item" to="/how-it-works">
              How it Works
            </Link>
          </li>
          <li className="nav-item nav-link-divider" onClick={closeMobileMenu}>
            <span>|</span>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link className="nav-link desktop-item" to="/our-team">
              Our Team
            </Link>
          </li>
          <li className="nav-item nav-link-divider" onClick={closeMobileMenu}>
            <span>|</span>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link className="nav-link desktop-item" to="contact-us">
              Contact Us
            </Link>
          </li>
          <li className="nav-item nav-link-divider">
            <span>|</span>
          </li>
          <li className="nav-item" onClick={closeMobileMenu}>
            <Link className="nav-link desktop-item" to="blogs">
              Blogs
            </Link>
          </li>
          <li className="nav-item nav-link-divider">
            <span>|</span>
          </li>

          <li>
            <Link
              to="/quote"
              className="nav-link desktop-item"
              onClick={closeMobileMenu}
            >
              Request Quote
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default React.memo(Navbar);
