import React from "react";
import Banner6 from "../partials/Banner/Banner6";
import Section1 from "../includes/PorchPiracyAGlobalIssue/Section1";
import { Helmet } from "react-helmet";

const PorchPiracyAGlobalIssue = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://brainbox.company/blogs/porch-piracy-in-the-world",
    },
    headline: "Porch piracy is becoming a global issue",
    image: "https://brainbox.company/images/blogs/blog-10.png",
    author: {
      "@type": "Organization",
      name: "Brain Box",
      url: "https://brainbox.company",
    },
    publisher: {
      "@type": "Organization",
      name: "Brain Box",
      logo: {
        "@type": "ImageObject",
        url: "https://brainbox.company/images/logo-b.png",
      },
    },
    datePublished: "2023-07-05",
    dateModified: "2023-07-06",
  };

  const imgPath = "url(" + devUrl + "images/our-team/ourteam-banner.png)";

  const bgColor = "$blue-color";
  const minHeight = "400px";

  return (
    <>
      <Helmet>
        <title>Porch piracy is becoming a global issue</title>
        <meta
          name="description"
          content="People in the United States of America, the United Kingdom, and Canada are losing their packages to porch pirates, especially during the holiday season."
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <link
          rel="canonical"
          href="https://brainbox.company/blogs/porch-piracy-in-the-world"
        />
      </Helmet>
      <main>
        <article className="our-team">
          <Banner6
            imgPath={imgPath}
            bgColor={bgColor}
            minHeight={minHeight}
            text1={"Is porch piracy just a thing in the US?"}
          />
          <div className="bg-design">
            <Section1 url={devUrl} />
          </div>
        </article>
      </main>
    </>
  );
};

export default React.memo(PorchPiracyAGlobalIssue);
