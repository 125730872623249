import React from "react";
import Banner3 from "../partials/Banner/Banner3";
import Section1 from "../includes/About/Section1";
import Section2 from "../includes/About/Section2";
import { Helmet } from "react-helmet";
// import Section11 from "../includes/About/Section11";

const About = ({ devUrl }) => {
  const imgPath = "url(" + devUrl + "images/about/about-bg-banner.jpg)";

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <link rel="canonical" href="https://brainbox.company/about-us" />
      </Helmet>
      <main>
        <article className="about">
          <Banner3
            text1={"Combating Porch Piracy"}
            text2={"One Brain Box at a Time"}
            imgPath={imgPath}
          />
          <Section1 url={devUrl} />
          <Section2 url={devUrl} />
          {/* <Section11 url={devUrl} /> */}
        </article>
      </main>
    </>
  );
};

export default React.memo(About);
