import React from 'react';


const Section1 = ({url})=>{

    return(

        <section className="section1">

        {/* <div className="container-fluid">
            <div className="row">
              <div className="col-12 wrapper1">
                  <p>
                      Officers A Frank Albergo, president of the Postal Police Officers Association, recently said, 
                  </p>
                  <p>
                      <b> "Mail theft is at epidemic proportions; it's never been higher" </b>
                  </p>
              </div>
            </div>
        </div> */}

        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="sec2-content">
                        <p>Come rain, hail, or snow – Brain Box keeps your package secure with its compartmentalized features.</p>
                        <p>The high-quality materials used in the production of Brain Box mean that your house retains its curbside appeal while you operate and control it remotely, or in person. Brain Box is spacious enough to store large and small packages, and its temperature-controlled sensors let you know when the ice cream is losing it’s cool.</p>
                        <p>The brand-new iPhone you waited months for, or the ice cream you were craving? All gone. Hundreds of dollars up in the air. As it is, these circumstances plague the online shopping experience. With no place to keep your items safe, you fear porch piracy.</p>
                        <p>During the pandemic, our only consolation came from online shopping; through freshly packaged brown boxes at the doorstep. As exciting as the concept is, it’s also anxiety-inducing, especially because matching schedules with delivery timings proves to be a hassle.</p>
                        <p>Pre-pandemic, you either had to race from work to retrieve your package or would come home to an empty porch, items stolen. That is the mindset we went in with while creating Brain Box – your new, state-of-the-art storage experience!</p>
                    </div>
                </div>
            </div>
        </div>

      </section>

    )

}

export default React.memo(Section1);