import React from "react";

const Banner6 = ({ imgPath, bgColor, minHeight, text1 }) => {
  return (
    <section className="banner-style-6" style={{ backgroundImage: imgPath }}>
      <div
        className="wrapper"
        style={{ backgroundColor: bgColor, minHeight: minHeight }}
      >
        <div className="container">
          <div className="row" style={{ minHeight: minHeight }}>
            <div className="col-xl-7">
              <div className="text-wrapper">
                <div
                  className="text-content"
                  data-aos="fade-right"
                  data-aos-duration="2000"
                >
                  <h1 className="banner-title blogs-banner">{text1}</h1>
                </div>
                <div
                  className="text-bg"
                  data-aos="fade-right"
                  data-aos-duration="2100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner6;
