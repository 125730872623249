import React from 'react';
import {Link} from 'react-router-dom';

const Section3 = ({url})=>{

    return(

        <section className="section3">

        <div className="container">
            <div className="row row-bg"  
                // style={{ backgroundImage: 'url('+ url + 'images/porch-piracy/sec-3.png)' }}
                data-aos="zoom-in"
                data-aos-duration="1000"
                >
                <div className="col-md-6">
                    <div className="card full-card">
                    <img src={url + 'images/porch-piracy/porch-piracy-right.jpg'} className="card-img-top" alt=""
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        />
                        <div className="card-body" style={{ backgroundImage:  'url('+url + 'images/porch-piracy/card-body-bg.png)' }}>
                            <h5 className="card-title">Porch Piracy Statistics 2021</h5>
                            <ul>
                                <li><p>72% of Americans say the COVID-19 pandemic has made them more concerned about porch piracy.</p></li>
                                <li><p>64.1 % of Americans have been a victim of package theft.</p></li>
                                <li><p>More than 3 in 4 Americans have been a victim of package theft in their lifetime 53.5 % have had multiple packages stolen.</p></li>
                                <li><p>More than half of all packages stolen are valued between $50-$200</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                <div className="card full-card">
                    <img src={url + 'images/porch-piracy/porch-piracy-left.jpg'} className="card-img-top" alt="" 
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        />
                        <div className="card-body" style={{ backgroundImage:  'url('+url + 'images/porch-piracy/card-body-bg.png)' }}>
                            <h5 className="card-title">People are responding more strongly to package theft. Here are some steps victims of package theft reported taking after the incident.</h5>
                            <ul>
                                <li><p>56% said they added a home security system after a theft.</p></li>
                                <li><p>46% say they alerted neighbors about the incident.</p></li>
                                <li><p>38% arranged for carriers to leave parcels in a discreet spot.</p></li>
                                <li><p>Now, you can purchase Brain Box; all porch piracy concerns will cease to exist!</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="req-quote-div">
                        <Link 
                            to="/quote" 
                            className="btn sec3-btn-green req-quote-div"
                            data-aos="zoom-in"
                            data-aos-duration="2000"
                        >
                            Request for Quote
                        </Link>
                    </div>
                </div>
            </div>
        </div>

      </section>

    )

}

export default React.memo(Section3);